import { Component, inject, Input } from '@angular/core';
import { StepperComponent } from '../stepper/stepper.component';
import { QuestionService } from 'src/app/services/question.service';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'app-test-start-page',
  imports: [StepperComponent, ButtonComponent],
  templateUrl: './test-start-page.component.html',
  styleUrl: './test-start-page.component.scss',
})
export class TestStartPageComponent {
  @Input() name: string = 'Herr Dr. Finapi Demobank';

  quests = inject(QuestionService);

  stepsCount = this.quests.steps.length;
}
