import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorPageComponent } from 'src/app/pages/footer-navigation/error-page/error-page.component';
import { KeycloakAuthGuard } from './guards/keycloakAuth.guard';
import { DatenschutzComponent } from './pages/footer-navigation/datenschutz/datenschutz.component';
import { DisclaimerComponent } from './pages/footer-navigation/disclaimer/disclaimer.component';
import { FooterComponent } from './pages/footer-navigation/footer/footer.component';
import { ImpressumComponent } from './pages/footer-navigation/impressum/impressum.component';
import { QuestionnaireComponent } from './pages/questionnaire/questionnaire.component';
import { BlankoDokumenteComponent } from './pages/questionnaire/questions/blanko-dokumente/blanko-dokumente.component';
import { DepotbankWahlComponent } from './pages/questionnaire/questions/depotbank-wahl/depotbank-wahl.component';
import { DokumenteComponent } from './pages/questionnaire/questions/dokumente/dokumente.component';
import { EinsichtComponent } from './pages/questionnaire/questions/einsicht/einsicht.component';
import { IdentifizierungComponent } from './pages/questionnaire/questions/identifizierung/identifizierung.component';
import { OverviewComponent } from './pages/questionnaire/questions/overview/overview.component';
import { ZusammenfassungComponent } from './pages/questionnaire/questions/zusammenfassung/zusammenfassung.component';
import { InstitutionIdFromQueryParamsGuard } from './guards/institution-id-from-query-params.guard';
import { TestMainPageComponent } from './component/test-main-page/test-main-page.component';
import { TestStartPageComponent } from './component/test-start-page/test-start-page.component';
import { TestLastPageComponent } from './component/test-last-page/test-last-page.component';
import { TestKontoEroeffnenComponent } from './component/test-konto-eroeffnen/test-konto-eroeffnen.component';

// Routes für alle componenten/seiten
const routes: Routes = [
  {
    path: '',
    component: QuestionnaireComponent,
    canActivate: [KeycloakAuthGuard, InstitutionIdFromQueryParamsGuard],
    children: [
      {
        path: 'overview',
        component: OverviewComponent,
      },

      {
        path: 'bank-wahl',
        component: DepotbankWahlComponent,
      },
      {
        path: 'dokumente',
        component: DokumenteComponent,
      },
      {
        path: 'zusammenfassung',
        component: ZusammenfassungComponent,
      },
      {
        path: 'blanko-dokumente',
        component: BlankoDokumenteComponent,
      },
      {
        path: 'einsicht',
        component: EinsichtComponent,
      },
      {
        path: 'identifizierung',
        component: IdentifizierungComponent,
      },
    ],
  },
  {
    path: 'testmain',
    component: TestMainPageComponent,
  },
  {
    path: 'teststart',
    component: TestStartPageComponent,
  },
  {
    path: 'testlast',
    component: TestLastPageComponent,
  },
  {
    path: 'testkonto',
    component: TestKontoEroeffnenComponent,
  },
  {
    path: 'info/impressum',
    component: ImpressumComponent,
  },
  {
    path: 'info/datenschutz',
    component: DatenschutzComponent,
  },
  {
    path: 'info/disclaimer',
    component: DisclaimerComponent,
  },
  {
    path: 'info/error-page',
    component: ErrorPageComponent,
  },
  {
    path: 'info/no-result-page',
    component: ErrorPageComponent,
  },
  {
    path: 'info/not-accepted',
    component: ErrorPageComponent,
  },
  {
    path: 'info/identity-error',
    component: ErrorPageComponent,
  },
  {
    path: 'info/last-identity-error',
    component: ErrorPageComponent,
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.routes').then((m) => m.ADMIN_ROUTES),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
