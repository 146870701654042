import { AuthService } from 'src/app/services/auth.service';
import { QuestionService } from 'src/app/services/question.service';

import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from 'src/app/component/button/button.component';
import { StepperComponent } from 'src/app/component/stepper/stepper.component';
import { TranslationDirective } from '../../../../component/translata-modal/translation.directive';
import { QuestionTemplate } from '../question-template';
import { SeparatorComponent } from '../../../../component/separator/separator.component';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  imports: [TranslationDirective, TranslateModule, ButtonComponent, StepperComponent, SeparatorComponent],
})
export class OverviewComponent extends QuestionTemplate {
  welcomeName: string;
  headerImagePath: string;
  logoImagePath: string;
  quests = inject(QuestionService);

  stepsCount = this.quests.steps().length;
  constructor(auth: AuthService, questionService: QuestionService, route: ActivatedRoute) {
    const url = route.snapshot.url;
    let path = '';
    if (url.length > 0) path = url[0].path;
    super(auth, questionService, path);
    this.questionName = 'overview';
    let index = this.auth.findFlaggedNaturalPerson(this.auth.user.natPersonen);
    let title = this.auth.user.natPersonen[index].Person.Titel;
    let vorname = this.auth.user.natPersonen[index].Person.Vorname;
    let nachname = this.auth.user.natPersonen[index].Person.Nachname;
    let anrede = this.auth.user.natPersonen[index].Person.Anrede;

    this.welcomeName = 'Hallo ' + anrede + ' ' + title + ' ' + vorname + ' ' + nachname;

    window.localStorage.clear();
  }
}
