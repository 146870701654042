<div>
  <div class="header-container">
    <h1>AGB</h1>
    <p>Allgemeine Geschäftsbedingungen der opta data Finance GmbH</p>
  </div>
  <div class="content">
    <p>
      Hier finden Sie die AGB und die weiteren Geschäftsbedingungen der opta data Finance GmbH im praktischen
      PDF-Format:
    </p>
    <ul>
      <li>
        <div class="list-container">
          <h4>AGB Zusatzleistungen</h4>
          <div class="d-flex justify-content-between">
            <p>Stand: 10.05.2022</p>
            <a target="_blank" href="https://www.optadata.de/dist/downloads/agb/agb-zusatzleistungen.pdf"
              ><mat-icon>search</mat-icon></a
            >
          </div>
        </div>
      </li>
      <li>
        <div class="list-container">
          <h4>AGB Abrechnungsstandard</h4>
          <div class="d-flex justify-content-between">
            <p>Stand: 10.05.2022</p>
            <a target="_blank" href="https://www.optadata.de/dist/downloads/agb/Anlage-A_AGB-Dienstleistung1.2.pdf"
              ><mat-icon>search</mat-icon></a
            >
          </div>
        </div>
      </li>
      <li>
        <div class="list-container">
          <h4>Anlage-A AGB-Online-Konfigurator</h4>
          <div class="d-flex justify-content-between">
            <p>Stand: 20.11.2020</p>
            <a
              target="_blank"
              href="https://www.optadata.de/dist/downloads/agb/Anlage-A_AGB-Online-Konfigurator_20201119.pdf"
              ><mat-icon>search</mat-icon></a
            >
          </div>
        </div>
      </li>
      <li>
        <div class="list-container">
          <h4>AGB Anlage A 1 Zusatzleistungen für Krankentransport / Rettungsdienste</h4>
          <div class="d-flex justify-content-between">
            <p>Stand: 08.12.2020</p>
            <a
              target="_blank"
              href="https://www.optadata.de/dist/downloads/agb/Anlage-A_zur-Dienstleistungsvereinbarung_AktivSchutz.pdf"
              ><mat-icon>search</mat-icon></a
            >
          </div>
        </div>
      </li>
      <li>
        <div class="list-container">
          <h4>AGB Anlage P Zusatzleistungen für Krankentransport / Rettungsdienste</h4>
          <div class="d-flex justify-content-between">
            <p>Stand: 16.12.2020</p>
            <a target="_blank" href="https://www.optadata.de/dist/downloads/agb/Anlage_P_TraRett.pdf"
              ><mat-icon>search</mat-icon></a
            >
          </div>
        </div>
      </li>
      <li>
        <div class="list-container">
          <h4>Anlage A – zur Dienstleistungsvereinbarung Select Liquid</h4>
          <div class="d-flex justify-content-between">
            <p>Stand: Mai 2022</p>
            <a target="_blank" href="https://www.optadata.de/dist/downloads/agb/Anlage-A_Select-Liquid.pdf"
              ><mat-icon>search</mat-icon></a
            >
          </div>
        </div>
      </li>
      <li>
        <div class="list-container">
          <h4>Anlage B – zur Dienstleistungsvereinbarung Select Liquid</h4>
          <div class="d-flex justify-content-between">
            <p>Stand: Mai 2022</p>
            <a target="_blank" href="https://www.optadata.de/dist/downloads/agb/Anlage-B_Select-Liquid.pdf"
              ><mat-icon>search</mat-icon></a
            >
          </div>
        </div>
      </li>
      <li>
        <div class="list-container">
          <h4>Anhang zur Anlage A</h4>
          <div class="d-flex justify-content-between">
            <p>Stand: November 2022</p>
            <a target="_blank" href="https://www.optadata.de/dist/downloads/agb/Anhang-zur-Anlage-A.pdf"
              ><mat-icon>search</mat-icon></a
            >
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
