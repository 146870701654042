<div class="ablehnen-box">
  <h3 appTranslationModal [translate]="'FINAPI.ABLEHNEN_POPUP_TEXT'"></h3>
  <p appTranslationModal [translate]="'FINAPI.ABLEHNEN_POPUP_SUBTEXT'"></p>
  <div class="textarea-Container">
    <textarea cols="60" rows="7" placeholder="Ihr Feedback"></textarea>
  </div>
  <div style="display: flex; justify-content: space-evenly">
    <app-button color="accent" [mat-dialog-close]="false">zurück</app-button>
    <app-button color="accent" [mat-dialog-close]="true">senden</app-button>
  </div>
</div>
