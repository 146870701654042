import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogClose } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from 'src/app/component/button/button.component';
import { AuthService } from 'src/app/services/auth.service';
import { TranslationDirective } from '../../translata-modal/translation.directive';

@Component({
  selector: 'app-angebot-abhlenen-dialog-box',
  templateUrl: './angebot-abhlenen-dialog-box.component.html',
  styleUrls: ['./angebot-abhlenen-dialog-box.component.scss'],
  imports: [TranslationDirective, TranslateModule, ButtonComponent, MatDialogClose],
})
export class AngebotAbhlenenDialogBoxComponent {
  imagelang: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private auth: AuthService
  ) {
    this.imagelang = this.auth.user.lang;
  }
}
