<div class="test-main">
  <app-separator></app-separator>

  <app-stepper></app-stepper>

  <app-separator></app-separator>

  <p class="title">Ihre persönliche Produktauswahl</p>

  <app-separator></app-separator>
  <app-geschaeftskonto></app-geschaeftskonto>
  <app-separator></app-separator>
  <app-zubuchbar-optionen></app-zubuchbar-optionen>
  <app-separator></app-separator>
  <app-button class="test-button"> Zum nächsten Schritt - Stammdaten </app-button>
  <app-separator></app-separator>

  <img src="assets/img/cover1.webp" alt="cover1" class="cover-img" width="500" />
  <app-separator></app-separator>
</div>
