import { Component } from '@angular/core';
import { GeschaeftskontoComponent } from '../geschaeftskonto/geschaeftskonto.component';
import { ZubuchbarOptionenComponent } from '../zubuchbar-optionen/zubuchbar-optionen.component';
import { SeparatorComponent } from '../separator/separator.component';
import { ButtonComponent } from '../button/button.component';
import { StepperComponent } from '../stepper/stepper.component';

@Component({
    selector: 'app-test-main-page',
    imports: [
        GeschaeftskontoComponent,
        ZubuchbarOptionenComponent,
        SeparatorComponent,
        ButtonComponent,
        StepperComponent,
    ],
    templateUrl: './test-main-page.component.html',
    styleUrl: './test-main-page.component.scss'
})
export class TestMainPageComponent {}
