import { Component } from '@angular/core';
import { CopyElementComponent } from '../copy-element/copy.component';
import { StepperComponent } from '../stepper/stepper.component';

@Component({
  selector: 'app-test-last-page',
  standalone: true,
  imports: [StepperComponent, CopyElementComponent],
  templateUrl: './test-last-page.component.html',
  styleUrl: './test-last-page.component.scss',
})
export class TestLastPageComponent {
  iban = 'DE53 6996 9588 0038 8900 03';
}
