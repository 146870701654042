<!-- footer -->

<div class="footer">
  <div class="footer-links">
    <a class="footer-link" href="/info/impressum" target="_blank" rel="noopener noreferrer">
      <span appTranslationModal [translate]="'ALLGEMEIN.FOOTER_LINK1'"></span>
    </a>
    <a class="footer-link" href="/info/datenschutz" target="_blank" rel="noopener noreferrer">
      <span appTranslationModal [translate]="'ALLGEMEIN.FOOTER_LINK2'"></span>
    </a>
    <a class="footer-link" href="/info/disclaimer" target="_blank" rel="noopener noreferrer">
      <span appTranslationModal [translate]="'ALLGEMEIN.FOOTER_LINK3'"></span>
    </a>
  </div>

  <img class="one-logo" src="assets/img/optadatabanking_Logo_neu.png" alt="logo" width="200" />

  <p class="c-text" appTranslationModal [translate]="'ALLGEMEIN.FOOTER_TEXT'"></p>
</div>
