<div class="dokumente-container">
  <div *ngIf="showAlert" class="overlay" (click)="close()">
    <div class="custom-alert-box" (click)="$event.stopPropagation()">
      <div class="alert-header">
        <span class="alert-title">Hinweis</span>
        <app-button class="close-button" (click)="close()">&times;</app-button>
      </div>
      <div class="alert-body">
        <p>{{ message }}</p>
      </div>
      <div class="alert-footer">
        <app-button class="confirm-button" (click)="close()">OK</app-button>
      </div>
    </div>
  </div>

  <h2 class="text-primary" appTranslationModal [translate]="'Dokumente und wirtschaftlich Berechtigte'"></h2>

  <div class="content-cards">
    <div class="card">
      <app-content-card>
        <form>
          <div class="row">
            <div class="col-12">
              <div class="row section-container">
                <h3 appTranslationModal [translate]="'STAMMDATEN.DOKUMENTE_HEADER'"></h3>
                <p style="padding-left: 12px" appTranslationModal [translate]="'STAMMDATEN.DOKUMENTE_TEXT'"></p>
                <div style="padding-left: 4px">
                  <table>
                    <thead>
                      <tr>
                        <th [translate]="'STAMMDATEN.DOCUMENT_TITLE'"></th>
                        <!-- <th [translate]="'STAMMDATEN.FILENAME'"></th> -->
                        <th [translate]="'STAMMDATEN.ACTION'"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let document of documents">
                        <td style="width: 23rem">{{ document.title }}</td>
                        <td style="width: 6rem; display: flex; column-gap: 8px; flex-direction: row">
                          <button class="btn-icon">
                            <ng-container *ngIf="jurPerson">
                              <mat-icon
                                class="info-icon"
                                [style.color]="hasFileOrBase64(document.title) ? '#7cd758' : '#ea110d'"
                              >
                                {{ hasFileOrBase64(document.title) ? 'check' : 'close' }}
                              </mat-icon>
                            </ng-container>
                          </button>
                          <button
                            class="btn-icon"
                            style="cursor: pointer"
                            (click)="fileInputs.click()"
                            id="upload-button-{{ document.title }}"
                          >
                            <mat-icon
                              class="info-icon"
                              [ngStyle]="{
                                cursor: 'pointer',
                                color: hasFileOrBase64(document.title) ? '#7cd758' : '#ea110d',
                              }"
                            >
                              publish
                            </mat-icon>
                          </button>

                          <!-- Keep input hidden but use more accessible ID and ref -->
                          <input
                            id="file-input-{{ document.title }}"
                            #fileInputs
                            type="file"
                            accept=".pdf"
                            style="display: none"
                            (change)="uploadRechtsDocument(fileInputs.files, document.title)"
                          />
                          <button
                            class="btn-icon"
                            style="cursor: pointer"
                            [disabled]="!hasFileOrBase64(document.title)"
                            (click)="handleClick(document.title)"
                          >
                            <mat-icon class="info-icon">search</mat-icon>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </form>
      </app-content-card>
    </div>
    <div class="card">
      <app-content-card>
        <form>
          <div class="row">
            <div class="col-12">
              <div class="row section-container">
                <h3 appTranslationModal [translate]="'STAMMDATEN.DOKUMENTE_HEADER1'"></h3>
                <p style="padding: 0 0 12px 12px" appTranslationModal [translate]="'STAMMDATEN.DOKUMENTE_TEXT1'"></p>
                <div style="padding-left: 4px">
                  <table>
                    <thead>
                      <tr>
                        <th [translate]="'Vorname(n)/Nachname'"></th>
                        <th [translate]="'Geburtsdatum'"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let natPerson of natPersonen; let i = index">
                        <td
                          style="width: 20rem"
                          *ngIf="
                            natPerson.Person.Wirtschaftlichberechtigt || natPerson.Person.FiktivWirtschaftlichberechtigt
                          "
                        >
                          {{ natPerson.Person.Nachname + ' ' + natPerson.Person.Vorname }}
                        </td>
                        <td
                          style="width: 8rem; display: flex; justify-content: space-between; align-items: center"
                          *ngIf="
                            natPerson.Person.Wirtschaftlichberechtigt || natPerson.Person.FiktivWirtschaftlichberechtigt
                          "
                        >
                          {{ natPerson.Person.Geburtsdatum | date }}

                          <mat-icon class="info-icon" [style.color]="'#7cd758'"> check </mat-icon>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div class="col info-container">
          <br />
          <div class="line-wrapper">
            <mat-icon class="info-icon">info</mat-icon>
            <div>
              <span appTranslationModal [translate]="'STAMMDATEN.MARKIERUNG_INFO'"></span>
              <span><mat-icon class="check-icon ckeckIconHint">check</mat-icon></span>
              <span appTranslationModal [translate]="'STAMMDATEN.MARKIERUNG_INFO_END'"></span>
            </div>
          </div>
          <br />
          <div class="row abweichung-card">
            <div class="col-12 line-wrapper">
              <div>
                <span
                  appTranslationModal
                  [translate]="
                    'Bei abweichenden Daten oder notwendigen Ergänzungen, teilen Sie uns diese bitte hier mit!'
                  "
                ></span>
              </div>
            </div>
            <div class="col-12 d-flex justify-content-center">
              <app-button color="accent" (click)="openPopUp()">
                {{ 'STAMMDATEN.ABWEICHUNGEN_MELDEN' | translate }}
              </app-button>
            </div>
          </div>
        </div>

        <form class="col-xl-10 card-container mb-3" [formGroup]="answerFormGroup" *ngIf="abweichungenGemeldet">
          <div class="d-flex flex-column">
            <h2 appTranslationModal [translate]="'STAMMDATEN.ABWEICHUNGEN_TITLE'"></h2>
            <div class="row">
              <div class="col-lg-6">
                <app-inputtext
                  [control]="answerFormGroup.controls['eingetragenerName']"
                  [label]="'STAMMDATEN.FIRMENNAME' | translate"
                  [readonly]="true"
                ></app-inputtext>
              </div>
              <div class="col-12 col-lg-6">
                <app-inputtext
                  [control]="answerFormGroup.controls['titel']"
                  [label]="'STAMMDATEN.TITEL' | translate"
                  [readonly]="true"
                ></app-inputtext>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-lg-6">
                <app-inputtext
                  [control]="answerFormGroup.controls['vorname']"
                  [label]="'STAMMDATEN.VORNAME' | translate"
                  [readonly]="true"
                ></app-inputtext>
              </div>
              <div class="col-12 col-lg-6">
                <app-inputtext
                  [control]="answerFormGroup.controls['nachname']"
                  [label]="'STAMMDATEN.NACHNAME' | translate"
                  [readonly]="true"
                ></app-inputtext>
              </div>
            </div>
            <div class="col-12">
              <app-inputtext
                [control]="answerFormGroup.controls['abweichungNachricht']"
                [label]="'STAMMDATEN.NACHRICHT' | translate"
                [readonly]="false"
              ></app-inputtext>
              <!-- <mat-form-field floatLabel="always" class="full-width" appearance="outline">
              <mat-label [translate]="'STAMMDATEN.NACHRICHT'"></mat-label>
              <textarea
                matInput
                formControlName="abweichungNachricht"
                placeholder="Bitte geben Sie Abweichungen ein..."
                rows="4"
              ></textarea>
            </mat-form-field> -->
            </div>
            <div class="d-flex justify-content-center">
              <div class="text-center p-2 m-1">
                <app-button type="button" (click)="closePopUp()" color="accent">
                  {{ 'STAMMDATEN.ABBRECHEN' | translate }}
                </app-button>
              </div>
              <div class="text-center p-2 m-1">
                <app-button
                  type="button"
                  (click)="abweichungenSenden()"
                  color="accent"
                  [disabled]="!answerFormGroup.controls['abweichungNachricht'].valid"
                >
                  Senden
                </app-button>
              </div>
            </div>
          </div>
        </form>
      </app-content-card>
    </div>
  </div>

  <mat-progress-spinner style="position: absolute" *ngIf="isLoading" mode="indeterminate"></mat-progress-spinner>
  <br />

  <app-content-card class="custom-card" [maxWidth]="'unset'">
    <form [formGroup]="answerFormGroup">
      <div class="row">
        <div class="col-12">
          <h2 appTranslationModal [translate]="'STAMMDATEN.CONFIRM_DOKUMENTE'"></h2>
          <hr />
          <div class="confirm-checkbox">
            <app-checkbox
              [checkBoxControl]="answerFormGroup.controls['bestätigt_document1']"
              data-cy="checkbox-complete-document"
            >
              <p class="text-bold" appTranslationModal [translate]="'STAMMDATEN.COMPLETE_DOCUMENTS_CONFIRM'"></p
            ></app-checkbox>
          </div>
        </div>
        <div class="col-12 mt-2">
          <p
            appTranslationModal
            [translate]="'Bitte überprüfen Sie die Richtigkeit und Akualität der wirtschaftlich Berechtigen'"
          ></p>
          <div class="confirm-checkbox">
            <app-checkbox
              [checkBoxControl]="answerFormGroup.controls['bestätigt_document']"
              data-cy="checkbox-document-wirtschaftlich-berechtigten"
            >
              <p
                class="text-bold"
                appTranslationModal
                [translate]="
                  'Hiermit bestätige ich die Richtigkeit und Vollständigkeit der wirtschaftlich Berechtigten.'
                "
              ></p
            ></app-checkbox>
          </div>
        </div>
      </div>
    </form>
  </app-content-card>

  <app-separator></app-separator>

  <app-button
    (click)="btnService.handleSaveAndDisableButton()"
    color="primary"
    [disabled]="btnService.isButtonDisabled() || btnService.isSaveAndContinueDisabled"
  >
    <span class="button-size-big" appTranslationModal [translate]="questionService.getNextButtonTitle()"></span>
  </app-button>
  <app-separator></app-separator>
</div>
