<div class="blanko-dokumente-container">
  <div class="col-xl-10">
    <h1 class="title" appTranslationModal [translate]="'RECHTLICHES.TITLE'"></h1>
    <br />
  </div>
  <app-content-card>
    <form [formGroup]="answerFormGroup">
      <div class="row">
        <div class="col-12">
          <div class="row pb-4" id="Rechtliche Hinweise" *ngIf="!juriPerson">
            <h2 class="text-primary" appTranslationModal [translate]="'RECHTLICHES.RECHTLICHE_TITLE'"></h2>
            <div class="line-wrapper-start">
              <mat-icon style="overflow: inherit; color: #006986" class="error-icon">remove</mat-icon>
              <p appTranslationModal [translate]="'RECHTLICHES.RECHTLICHE_TEXT1'"></p>
            </div>
            <div class="line-wrapper-start">
              <mat-icon style="overflow: inherit; color: #006986" class="error-icon">remove</mat-icon>
              <p appTranslationModal [translate]="'RECHTLICHES.RECHTLICHE_TEXT2'"></p>
            </div>
            <div class="line-wrapper-start pb-4">
              <mat-icon style="overflow: inherit; color: #006986" class="error-icon">remove</mat-icon>
              <p appTranslationModal [translate]="'RECHTLICHES.RECHTLICHE_TEXT3'"></p>
            </div>

            <app-checkbox
              [checkBoxControl]="answerFormGroup.controls['hinweise']"
              data-cy="natPerson_rechtliche-hinweise"
            >
              <p appTranslationModal [translate]="'RECHTLICHES.RECHTLICHE_CHECKBOX_TEXT'"></p
            ></app-checkbox>
          </div>

          <div class="row pb-4" id="Rechtliche Hinweise" *ngIf="juriPerson">
            <h2 class="text-primary" appTranslationModal [translate]="'RECHTLICHES.JURRECHTLICHE_TITLE'"></h2>
            <div class="line-wrapper-start">
              <mat-icon style="overflow: inherit; color: #006986" class="error-icon">remove</mat-icon>
              <p appTranslationModal [translate]="'RECHTLICHES.JURTEXT1'"></p>
            </div>
            <div class="line-wrapper-start">
              <mat-icon style="overflow: inherit; color: #006986" class="error-icon">remove</mat-icon>
              <p appTranslationModal [translate]="'RECHTLICHES.JURTEXT2'"></p>
            </div>
            <div class="line-wrapper-start pb-4">
              <mat-icon style="overflow: inherit; color: #006986" class="error-icon">remove</mat-icon>
              <p appTranslationModal [translate]="'RECHTLICHES.JURTEXT3'"></p>
            </div>

            <app-checkbox
              [checkBoxControl]="answerFormGroup.controls['hinweise']"
              data-cy="jurPerson_rechliche-hinweise"
            >
              <p appTranslationModal [translate]="'RECHTLICHES.JURTEXT4'"></p>
            </app-checkbox>
          </div>

          <div *ngIf="!juriPerson" class="row pb-4" id="selbstauskunft">
            <h2 class="text-primary" appTranslationModal [translate]="'RECHTLICHES.SELBSTAUSKUNFT_TITLE'"></h2>

            <app-checkbox
              [checkBoxControl]="answerFormGroup.controls['nurDeSteuerpflicht']"
              data-cy="natPerson_selbstauskunft1"
            >
              <p appTranslationModal [translate]="'RECHTLICHES.SELBSTAUSKUNFT_CHECKBOX1_TEXT'"></p>
            </app-checkbox>

            <app-checkbox
              [checkBoxControl]="answerFormGroup.controls['usSteuerpflicht']"
              data-cy="natPerson_selbstauskunft2"
            >
              <p appTranslationModal [translate]="'RECHTLICHES.SELBSTAUSKUNFT_CHECKBOX2_TEXT'"></p>
            </app-checkbox>
          </div>

          <div *ngIf="juriPerson" class="row pb-4" id="selbstauskunft">
            <h2 class="text-primary" appTranslationModal [translate]="'RECHTLICHES.JURTEXT5'"></h2>
            <div class="line-wrapper-start">
              <mat-icon style="overflow: inherit; color: #006986" class="error-icon">remove</mat-icon>
              <p appTranslationModal [translate]="'RECHTLICHES.JURTEXT6'"></p>
            </div>

            <div class="line-wrapper-start">
              <mat-icon style="overflow: inherit; color: #006986" class="error-icon">remove</mat-icon>
              <p appTranslationModal [translate]="'RECHTLICHES.JURTEXT7'"></p>
            </div>

            <div class="line-wrapper-start">
              <mat-icon style="overflow: inherit; color: #006986" class="error-icon">remove</mat-icon>
              <p appTranslationModal [translate]="'RECHTLICHES.JURTEXT8'"></p>
            </div>

            <div class="line-wrapper-start">
              <mat-icon style="overflow: inherit; color: #006986" class="error-icon">remove</mat-icon>
              <p appTranslationModal [translate]="'RECHTLICHES.JURTEXT9'"></p>
            </div>

            <br /><br /><br />

            <app-checkbox
              [checkBoxControl]="answerFormGroup.controls['nurDeSteuerpflicht']"
              data-cy="jurPerson_selbstauskunft"
            >
              <p appTranslationModal [translate]="'RECHTLICHES.JURTEXT10'"></p>
            </app-checkbox>
          </div>

          <div *ngIf="!juriPerson" class="row pb-4" id="agbs">
            <h2 class="text-primary" appTranslationModal [translate]="'RECHTLICHES.ALLGEMEINE_TITLE'"></h2>
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title class="text-bold">
                    <p appTranslationModal [translate]="'RECHTLICHES.ALLGEMEINE_AGB_TITLE'"></p>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <table mat-table [dataSource]="agbDataSource" class="full-width">
                  <ng-container matColumnDef="name">
                    <td mat-cell *matCellDef="let element">
                      <h3 style="word-break: break-word">{{ element.name }}</h3>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="download">
                    <td mat-cell class="text-right" *matCellDef="let element">
                      <button
                        class="search-button"
                        (click)="openRechtlichesDocument(element.position)"
                        data-cy="natPerson_search-icon"
                      >
                        <mat-icon>search</mat-icon>
                      </button>
                    </td>
                  </ng-container>

                  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
              </mat-expansion-panel>
            </mat-accordion>

            <app-checkbox [checkBoxControl]="answerFormGroup.controls['agbs']" data-cy="natPerson_agbs">
              <p appTranslationModal [translate]="'RECHTLICHES.ALLGEMEINE_CHECKBOX_TEXT'"></p>
            </app-checkbox>
          </div>

          <div *ngIf="!juriPerson" class="row pb-4" id="datenschutzhinweise">
            <h2 class="text-primary" appTranslationModal [translate]="'RECHTLICHES.DATENSCHUTZWEISE_TITLE'"></h2>
            <table mat-table [dataSource]="datenschutzDataSource" class="full-width">
              <ng-container matColumnDef="name">
                <td mat-cell *matCellDef="let element" style="padding-left: 0">
                  <h3>{{ element.name }}</h3>
                </td>
              </ng-container>
              <ng-container matColumnDef="download">
                <td mat-cell class="d-flex justify-content-end" *matCellDef="let element">
                  <button
                    class="search-button"
                    (click)="openRechtlichesDocument(element.position)"
                    data-cy="natPerson_search-icon"
                  >
                    <mat-icon>search</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>

            <app-checkbox [checkBoxControl]="answerFormGroup.controls['datenschutz']" data-cy="natPerson_datenschutz">
              <p appTranslationModal [translate]="'RECHTLICHES.DATENSCHUTZWEISE_CHECKBOX_TEXT'"></p>
            </app-checkbox>
          </div>

          <div *ngIf="!juriPerson" class="row pb-4" id="pep">
            <h2 class="text-primary" appTranslationModal [translate]="'RECHTLICHES.ERKLAERUNG_TITLE'"></h2>
            <div class="line-wrapper-start pb-4">
              <p appTranslationModal [translate]="'RECHTLICHES.ERKLAERUNG_TEXT'"></p>
            </div>

            <div [formGroup]="answerFormGroup" class="radio-pep">
              <input
                type="radio"
                formControlName="offentlichesAmt"
                [value]="false"
                data-cy="jurPerson_pep-radiobutton1"
              />
              <p appTranslationModal [translate]="'RECHTLICHES.ERKLAERUNG_RADIO1_TEXT'"></p>

              <input
                type="radio"
                formControlName="offentlichesAmt"
                [value]="true"
                data-cy="jurPerson_pep-radiobutton2"
              />
              <p appTranslationModal [translate]="'RECHTLICHES.ERKLAERUNG_RADIO2_TEXT'"></p>
            </div>

            @if (answerFormGroup.get('offentlichesAmt')?.value === true) {
              <app-inputtext
                [control]="answerFormGroup.controls['offentlichesAmtField'].value"
                [label]="'Amt Angaben'"
                [readonly]="false"
              ></app-inputtext>
            }
          </div>

          <div *ngIf="!juriPerson" class="row pb-4" id="einlagensicherung">
            <h2 class="text-primary" appTranslationModal [translate]="'RECHTLICHES.HINWEIS_TITLE'"></h2>
            <p appTranslationModal [translate]="'RECHTLICHES.HINWEIS_TEXT'"></p>
            <table mat-table [dataSource]="infoEinlegerDataSource" class="full-width">
              <ng-container matColumnDef="name">
                <td mat-cell *matCellDef="let element">
                  <h3>{{ element.name }}</h3>
                </td>
              </ng-container>
              <ng-container matColumnDef="download">
                <td mat-cell class="text-right" *matCellDef="let element">
                  <button
                    class="search-button"
                    (click)="openRechtlichesDocument(element.position)"
                    data-cy="natPerson_search-icon"
                  >
                    <mat-icon>search</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>

            <app-checkbox
              [checkBoxControl]="answerFormGroup.controls['einlagensicherung']"
              data-cy="natPerson_einlagensicherung"
            >
              <p appTranslationModal [translate]="'RECHTLICHES.HINWEIS_CHECKBOX_TEXT'"></p>
            </app-checkbox>
          </div>

          <div *ngIf="juriPerson" class="row pb-4" id="agbs">
            <h2 class="text-primary" appTranslationModal [translate]="'RECHTLICHES.JURTEXT11'"></h2>
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title class="text-bold">
                    <p appTranslationModal [translate]="'RECHTLICHES.JURTEXT12'"></p>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <table mat-table [dataSource]="agbDataSource" class="full-width">
                  <ng-container matColumnDef="name">
                    <td mat-cell *matCellDef="let element">
                      <h3 style="word-break: break-word">{{ element.name }}</h3>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="download">
                    <td mat-cell class="text-right" *matCellDef="let element">
                      <button
                        type="button"
                        class="search-button"
                        (click)="openRechtlichesDocument(element.position)"
                        data-cy="jurPerson_search-icon"
                      >
                        <mat-icon>search</mat-icon>
                      </button>
                    </td>
                  </ng-container>

                  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
              </mat-expansion-panel>
            </mat-accordion>

            <app-checkbox [checkBoxControl]="answerFormGroup.controls['agbs']" data-cy="jurPerson_agbs">
              <p appTranslationModal [translate]="'RECHTLICHES.JURTEXT13'"></p>
            </app-checkbox>
          </div>

          <div *ngIf="juriPerson" class="row pb-4" id="datenschutzhinweise">
            <h2 class="text-primary" appTranslationModal [translate]="'RECHTLICHES.JURTEXT14'"></h2>
            <table mat-table [dataSource]="datenschutzDataSource" class="full-width">
              <ng-container matColumnDef="name">
                <td mat-cell *matCellDef="let element" style="padding-left: 0">
                  <h3>{{ element.name }}</h3>
                </td>
              </ng-container>
              <ng-container matColumnDef="download">
                <td mat-cell class="d-flex justify-content-end" *matCellDef="let element">
                  <button
                    type="button"
                    class="search-button"
                    (click)="openRechtlichesDocument(element.position)"
                    data-cy="jurPerson_search-icon"
                  >
                    <mat-icon>search</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>

            <app-checkbox [checkBoxControl]="answerFormGroup.controls['datenschutz']" data-cy="jurPerson_datenschutz">
              <p appTranslationModal [translate]="'RECHTLICHES.JURTEXT150'"></p>
            </app-checkbox>
          </div>

          <div *ngIf="juriPerson" class="row pb-4" id="pep">
            <h2 class="text-primary" appTranslationModal [translate]="'RECHTLICHES.JURTEXT15'"></h2>
            <div class="line-wrapper-start pb-4">
              <p appTranslationModal [translate]="'RECHTLICHES.JURTEXT16'"></p>
            </div>
            <!-- <mat-radio-group formControlName="offentlichesAmt" (change)="radioPepValidate()"> -->
            <div [formGroup]="answerFormGroup" class="radio-pep">
              <input
                type="radio"
                formControlName="offentlichesAmt"
                [value]="false"
                data-cy="jurPerson_pep-radiobutton1"
              />
              <p appTranslationModal [translate]="'RECHTLICHES.JURTEXT17'"></p>

              <input
                type="radio"
                formControlName="offentlichesAmt"
                [value]="true"
                data-cy="jurPerson_pep-radiobutton2"
              />
              <p appTranslationModal [translate]="'RECHTLICHES.JURTEXT18'"></p>
            </div>

            @if (answerFormGroup.get('offentlichesAmt')?.value === true) {
              <app-inputtext
                [control]="answerFormGroup.controls['offentlichesAmtField'].value"
                [label]="'Amt Angaben'"
                [readonly]="false"
              ></app-inputtext>
            }
          </div>

          <div *ngIf="juriPerson" class="row pb-4" id="einlagensicherung">
            <h2 class="text-primary" appTranslationModal [translate]="'RECHTLICHES.JURTEXT19'"></h2>
            <p appTranslationModal [translate]="'RECHTLICHES.JURTEXT20'"></p>
            <table mat-table [dataSource]="infoEinlegerDataSource" class="full-width">
              <ng-container matColumnDef="name">
                <td mat-cell *matCellDef="let element" style="padding-left: 0">
                  <h3>{{ element.name }}</h3>
                </td>
              </ng-container>
              <ng-container matColumnDef="download">
                <td mat-cell class="d-flex justify-content-end" *matCellDef="let element">
                  <button
                    type="button"
                    class="search-button"
                    (click)="openRechtlichesDocument(element.position)"
                    data-cy="jurPerson_search-icon"
                  >
                    <mat-icon>search</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>

            <app-checkbox
              [checkBoxControl]="answerFormGroup.controls['einlagensicherung']"
              data-cy="jurPerson_einlagensicherung"
            >
              <p appTranslationModal [translate]="'RECHTLICHES.JURTEXT21'"></p>
            </app-checkbox>
          </div>

          <div *ngIf="!juriPerson" class="row pb-4" id="schufaZustimmung">
            <h2 class="text-primary" appTranslationModal [translate]="'RECHTLICHES.SCHUFA_TITLE'"></h2>

            <p appTranslationModal [translate]="'RECHTLICHES.SCHUFA_TEXT'"></p>

            <app-checkbox
              [checkBoxControl]="answerFormGroup.controls['schufaZustimmung']"
              data-cy="natPerson_schufa-zustimmung"
            >
              <p appTranslationModal [translate]="'RECHTLICHES.SCHUFA_CHECKBOX_TEXT'"></p>
            </app-checkbox>
          </div>

          <div *ngIf="juriPerson" class="row pb-4" id="schufaZustimmung">
            <h2 class="text-primary" appTranslationModal [translate]="'RECHTLICHES.JURTEXT22'"></h2>

            <p appTranslationModal [translate]="'RECHTLICHES.JURTEXT23'"></p>

            <app-checkbox
              [checkBoxControl]="answerFormGroup.controls['schufaZustimmung']"
              data-cy="jurPerson_schufa-zustimmung"
            >
              <p appTranslationModal [translate]="'RECHTLICHES.JURTEXT24'"></p>
            </app-checkbox>
          </div>
        </div>
      </div>
    </form>
  </app-content-card>

  <div class="col-xl-9 line-wrapper mt-2" *ngIf="!answerFormGroup.valid">
    <mat-icon style="overflow: inherit" class="error-icon odfincolor">error</mat-icon>
    <p appTranslationModal [translate]="'RECHTLICHES.CHECKBOX_BESTAETIGUNG_TEXT'"></p>
  </div>

  <div class="col-xl-9 ps-5">
    <ng-container *ngFor="let control of answerFormGroup.controls | keyvalue">
      <div class="d-flex" *ngIf="!control.value.valid">
        <p style="color: red" appTranslationModal [translate]="getControlTranslationKey(control.key)"></p>
      </div>
    </ng-container>
    <app-button color="primary" (click)="adminCheckAll()" *ngIf="isAdmin" class="pt-2">
      {{ 'RECHTLICHES.CHECK_ALL' | translate }}
      <mat-icon>check</mat-icon>
    </app-button>
  </div>

  <app-separator></app-separator>
  <app-button
    (click)="btnService.handleSaveAndDisableButton()"
    [disabled]="btnService.isButtonDisabled() || btnService.isSaveAndContinueDisabled"
  >
    <span class="button-size-big" appTranslationModal [translate]="questionService.getNextButtonTitle()"></span>
  </app-button>

  <app-separator></app-separator>
</div>
