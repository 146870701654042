<app-separator></app-separator>

<div class="onboarding">
  <div class="left-container">
    <img src="assets/img/Group.png" alt="cover" width="570" height="680" class="left-img" />
  </div>
  <div class="right-container">
    <div class="stepper">
      <app-stepper></app-stepper>
    </div>
    <p class="title">In {{ stepsCount }} Schritten zum neuen Geschäftskonto!</p>
    <div class="text">
      <p>{{ welcomeName }},</p>
      <p>
        Profitieren Sie von unseren attraktiven Konditionen und von unserem starken Partner im deutschen
        Gesundheitswesen. Eröffnen Sie schnell und leicht Ihr opta data Geschäftskonto.
      </p>
    </div>

    <div class="button">
      <app-button
        (click)="questionService.nextStep()"
        color="primary"
        [disabled]="answerFormGroup && !answerFormGroup.valid"
      >
        <span class="button-size-big" appTranslationModal [translate]="'WILLKOMMEN.BUTTON_TEXT'"></span>
      </app-button>
    </div>

    <div class="email">
      <p appTranslationModal [translate]="'WILLKOMMEN.BOTTOM_TEXT'"></p>
      <a
        style="font-size: large"
        href="mailto:optadatabanking@bankhausbauer.de?subject=Eröffnung meines opta data Geschäftskontos (Mandatsreferenz: {{
          (user.natPersonen[0].Mandatref || '').toString()
        }})"
        >optadatabanking&#64;bankhausbauer.de</a
      >
    </div>
  </div>
</div>
