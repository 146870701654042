import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth.service';
import { TranslationDirective } from '../../../component/translata-modal/translation.directive';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  imports: [TranslationDirective, TranslateModule],
})
export class FooterComponent {
  logoImage = 'assets/img/optadatabanking_Logo_neu.png';
  constructor(public authService: AuthService) {}
}
