<div class="onboarding">
  <div class="left-container">
    <img src="assets/img/Group.png" alt="cover" width="570" height="680" class="left-img" />
  </div>
  <div class="right-container">
    <div class="stepper">
      <app-stepper></app-stepper>
    </div>
    <p class="title">In {{ stepsCount }} Schritten zum neuen Geschäftskonto!</p>
    <div class="text">
      <p>Hallo {{ name }},</p>
      <p>
        Profitieren Sie von unseren attraktiven Konditionen und von unserem starken Partner im deutschen
        Gesundheitswesen. Eröffnen Sie schnell und leicht Ihr opta data Geschäftskonto.
      </p>
    </div>
    <div class="button">
      <app-button>opta data Geschäftskonto eröffnen</app-button>
    </div>
    <p class="email">Wenn Sie noch Fragen haben, schreiben Sie uns eine E-Mail: optadatabanking&#64;bankhausbauer.de</p>
  </div>
</div>
