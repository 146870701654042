<div class="header" *ngIf="showHeader">
  <div class="logo">
    <img class="logo-nav" src="assets/img/optadatabanking_Logo_neu.png" alt="logo" />
  </div>

  <div class="buttons">
    <p class="user-name">{{ auth.user.name }} {{ auth.user.lastname }}</p>

    @if (auth.user.rol !== Roles.client) {
      <app-button (click)="goToAdmin()">Admin</app-button>
    }

    <app-button (click)="logout()"> Logout</app-button>
  </div>
</div>
