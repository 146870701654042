import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgIf } from '@angular/common';
import { LoaderComponent } from '../../loader/loader.component';
import { TranslationDirective } from '../../translata-modal/translation.directive';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'app-download-dialog-box',
    templateUrl: './download-dialog-box.component.html',
    styleUrls: ['./download-dialog-box.component.scss'],
    imports: [
        NgIf,
        LoaderComponent,
        TranslationDirective,
        TranslateModule,
        MatIcon,
    ]
})
export class DownloadDialogBoxComponent {
  isDownloading: boolean;
  constructor(
    private dialogRef: MatDialogRef<DownloadDialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;
    this.isDownloading = this.data.isDownloading;
  }
}
