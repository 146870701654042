import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ButtonComponent } from 'src/app/component/button/button.component';
import { MatDialog } from '@angular/material/dialog';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AngebotAbhlenenDialogBoxComponent } from 'src/app/component/dialog-boxes/angebot-abhlenen-dialog-box/angebot-abhlenen-dialog-box.component';
import { Status } from 'src/app/models/user';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { TranslationDirective } from '../../../component/translata-modal/translation.directive';

@Component({
    selector: 'app-error-page',
    templateUrl: './error-page.component.html',
    styleUrls: ['./error-page.component.scss'],
    imports: [NgIf, TranslationDirective, TranslateModule, MatProgressSpinner, ButtonComponent]
})
export class ErrorPageComponent implements OnInit {
  isErrorPage = false;
  isNoResult = false;
  isNoAccepted = false;
  isNoIdentity = false;
  isLastNoIdentity = false;
  isLoading: boolean = false;
  errorMessage: string;
  canBeDisabled: boolean;
  errMsg: string;

  constructor(
    private router: Router,
    public auth: AuthService,
    public dialog: MatDialog,
    private adminService: AdminService
  ) {}

  ngOnInit(): void {
    this.incrementErrorCounter();
    this.errorMessage = this.auth.user?.finapi?.payload?.errorMessage || '';
    this.errMsg = 'Zugang zu Ihrem Bestandskonto ist aktuell nicht möglich';
    switch (this.router.url) {
      /*Add all use cases for error handaling*/
      case '/info/no-result-page': {
        this.isNoResult = true;
        break;
      }
      case '/info/not-accepted': {
        this.isNoAccepted = true;
        break;
      }
      case '/info/identity-error': {
        this.isNoIdentity = true;
        break;
      }
      case '/info/last-identity-error': {
        this.isLastNoIdentity = true;
        break;
      }
      default: {
        this.isErrorPage = true;
        break;
      }
    }
  }

  async acceptOffer() {
    const currentUser = this.auth.user;
    if (currentUser.finapi.counter === 3) {
      this.canBeDisabled = true;
      this.isLoading = true;

      try {
        await this.auth.updateAuswahlProdukt(this.auth.user._id).toPromise();
        await this.auth.updateQuestions(this.auth.user._id).toPromise();
        await this.auth.refreshUser().toPromise();
        const baseUrl = window.location.origin;
        window.location.href = baseUrl;
      } catch (error) {
        console.error('An error occurred during the acceptOffer process:', error);
      } finally {
        this.isLoading = false;
      }
    }
  }

  goBack() {
    if (this.auth.user.finapi.counter === 3) {
      this.openPopUp();
    } else {
      this.router.navigateByUrl('/');
    }
  }

  async openPopUp() {
    const dialogRef = this.dialog.open(AngebotAbhlenenDialogBoxComponent);
    const answer = await dialogRef.afterClosed().toPromise();

    if (answer === true) {
      this.canBeDisabled = true;
      this.isLoading = true;
      await this.adminService.updateSubStatus(this.auth.user._id, 'offeringRejected').toPromise();
      await this.auth.changeStatus(this.auth.user._id, Status.inactive).toPromise();
      await this.auth.updateAuswahlProdukt(this.auth.user._id).toPromise();
      await this.auth.updateQuestions(this.auth.user._id).toPromise();
      await this.auth.refreshUser().toPromise();
      window.location.href = environment.oneDashboardURL;
    }
  }

  incrementErrorCounter() {
    const currentUser = this.auth.user;
    currentUser.finapi.counter = 3;
    this.auth.updateUser(this.auth.user._id, currentUser.finapi.counter).subscribe();

    return currentUser.finapi.counter;
  }
}
