import { NgIf } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepper } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import { Rol, User } from 'src/app/models/user';
import { AdminService } from 'src/app/services/admin.service';
import { Agree21Service } from 'src/app/services/agree21.service';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { TokenService } from 'src/app/services/jwttoken.service';
import { NotificationService } from 'src/app/services/notification.service';
import { QuestionService } from 'src/app/services/question.service';
import { StepperComponent } from '../../component/stepper/stepper.component';
import { HeaderComponent } from '../header/header.component';

/**
 * Diese Komponente ist der Rahmen, in den alle Fragen des Fragebogens geladen werden.
 * Hier befinden sich die Schaltflächen Sichern/Nächste und Zurück.
 * Alle Fragekomponenten müssen die Klasse "question-template" extend. (hirearchi)
 */
@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss'],
  imports: [
    MatProgressSpinnerModule,
    MatTooltipModule,
    TranslateModule,
    RouterOutlet,
    HeaderComponent,
    StepperComponent,
    NgIf,
  ],
})
export class QuestionnaireComponent implements OnInit {
  stepNumber = 0;
  stepActive: boolean;
  stepPage: boolean;
  showHeader: boolean;
  showImage: boolean;
  showIconInButton: boolean;
  nextButtonTitle: string;
  isAbschluss: boolean;
  description: { title: string; text: string };
  descriptionImagePath: string;
  logoImagePath: string;
  imagelang: string;
  answerFormGroup: FormGroup;
  user: User;
  isAdminOrConsultant: boolean;
  pathIsEinsicht = false;
  logoImage;
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('myStep') myStep: MatStepper;
  favIcon: HTMLLinkElement | null = document.querySelector('#favIcon') as HTMLLinkElement;
  favDescription: HTMLElement | null = document.querySelector('#favDescription');
  bodyElement: HTMLElement | null = document.querySelector('#body');
  juriPerson: boolean = false;
  isLoading: boolean;
  isSaveAndContinueDisabled = false;
  timeLeft: string;
  berufsGruppe: any;
  dropdownOpen: boolean;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private questionService: QuestionService,
    private auth: AuthService,
    private notification: NotificationService,
    private translate: TranslateService,
    private adminService: AdminService,
    private dataService: DataService,
    private agree21Service: Agree21Service,
    private keycloakService: KeycloakService,
    public tokenservice: TokenService
  ) {
    this.stepPage = false;
    this.showHeader = false;
    this.showIconInButton = false;
    this.isAbschluss = false;
    this.description = { title: '', text: '' };
    this.nextButtonTitle = '';
    this.descriptionImagePath = '';
    this.logoImagePath = '';

    // Handle History navigation
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart && event.navigationTrigger === 'popstate')
        this._init(event.url.split('/')[2]);
    });
  }

  ngOnInit() {
    this.user = this.auth.user;
    this.imagelang = this.user.lang;
    const logoImageOD = 'assets/img/Bankhaus_Bauer_Logo.svg';
    this.logoImagePath = this.logoImagePath = logoImageOD;
    this.translate.use('odfin');
    this.isAdminOrConsultant = this.user.rol !== Rol.client;
    const question = this.questionService.getLastQuestion(this.auth.user.questions);
    if (this.route.firstChild) {
      const path = this.route.firstChild.snapshot.url[0].path;
      if (path === question.route) this._init(path);
    }
    this.router.navigate([question.route], { relativeTo: this.route }).then(() => {
      this._init(question.route);
      this.stepNumber = question.step || 0;
    });

    this.juriPerson = this.auth.isJurPerson();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.isSaveAndContinueDisabled = false; // Reset button state on navigation
        this._init(this.questionService.getCurrentPath());
      }
    });
  }

  refreshToken() {
    this.tokenservice.refreshTokenManually();
  }

  private async _init(path: string) {
    const logoImagePathOD = 'assets/img/menu-logo-odfin.svg';
    const logoImageOD = 'assets/img/Bankhaus_Bauer_Logo.svg';
    const questionObj = this.questionService.getQuestionObjByRoute(path);
    // this.currentQuestionIndex = this.questionService.getQuestionIndexByRoute(path);
    this.stepPage = questionObj?.stepPage || false;
    this.showHeader = questionObj?.showHeader || false;
    this.description = questionObj?.description || { title: '', text: '' };
    this.nextButtonTitle = questionObj?.nextButtonTitle || '';
    this.showIconInButton = questionObj?.showIconInButton || false;
    this.pathIsEinsicht = questionObj?.route === 'einsicht';
    this.berufsGruppe = this.auth.getBerufsGruppe();
    if (questionObj?.imagePath) {
      this.logoImage = logoImageOD;
      this.descriptionImagePath = questionObj.imagePath + '-odfin.png';
      this.logoImagePath = logoImagePathOD;
      this.favIcon!.href = 'assets/img/icons-input/favicon-optaData.svg';
      this.favDescription!.innerHTML = 'opta data Gruppe - Kontoeröffnung';
      this.bodyElement!.style.cssText = "font-family: 'Poppins'";
    }

    this.showImage = !!questionObj?.imagePath;
    this.stepNumber = questionObj?.step || 0;
    this.isAbschluss = this.stepNumber === 3;

    if (questionObj?.component) {
      this.answerFormGroup = questionObj.component.answerFormGroup;
    }
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  showCancelButton() {
    return this.adminService.getShowCancelButton();
  }
}
