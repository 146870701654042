<div class="identifizierung-container">
  <div class="content-container">
    <app-content-card class="center-content">
      <h1 appTranslationModal [translate]="'ABSCHLUSSSEITE.TITLE'"></h1>
      <p appTranslationModal [translate]="'ABSCHLUSSSEITE.MAIN_TEXT'"></p>
      <p style="margin-bottom: 5px" appTranslationModal [translate]="'ABSCHLUSSSEITE.CARD_BOTTOM_TEXT'"></p>
      <p style="margin-bottom: 15px" appTranslationModal [translate]="'ABSCHLUSSSEITE.CARD_BOTTOM_TEXT1'"></p>

      <div *ngIf="enableTagesgeldkonto && berufsGruppe?.tagesgeldkonto" class="tagesGeldkonto-iban">
        <span appTranslationModal [translate]="'ABSCHLUSSSEITE.tagesGeldkontoIban_TEXT'"></span>
        <span>&nbsp;</span>
        <span>
          <strong style="font-weight: 600">{{ tagesGeldkontoIban }}</strong>
        </span>
        <span>&nbsp;</span>
        <span appTranslationModal [translate]="'ABSCHLUSSSEITE.tagesGeldkontoIban1_TEXT'"></span><span>&nbsp;</span>
        <span>{{ berufsGruppe?.guthabenVerzinsung }} p.a. profitieren.</span>
      </div>
      <div *ngIf="kontoWechselSelected">
        <p appTranslationModal [translate]="'ABSCHLUSSSEITE.KONTOWECHSEL_TEXT'"></p>
        <div class="mt-2">
          <app-button (click)="openKontoWechsel()" color="accent">
            <p class="button-size-big" style="color: white; margin-top: 0">Kontowechselservice</p>
          </app-button>
        </div>
      </div>
    </app-content-card>
    <app-content-card class="center-content">
      <div>
        <span appTranslationModal [translate]="'ABSCHLUSSSEITE.IBAN_TEXT'" style="color: #006986"></span>
        <span>&nbsp;</span>
        <h2>
          <strong style="font-weight: 600">{{ customerIban }}</strong>
          <span style="padding-left: 1rem; cursor: pointer" (click)="copyToClipboard(customerIban)"
            ><img src="assets/icons/clipboard.svg"
          /></span>
        </h2>
      </div>
      <div class="giroCard">
        <img src="assets/img/banking-card.webp" style="width: 100%; height: 100%; max-width: 27rem" />
        <h3 class="name">{{ customerName }}</h3>
        <p class="iban-odfin">{{ customerIban }}</p>
        <p class="valid-odfin">12/27</p>
      </div>
      <div class="py-4">
        <app-button data-cy="BACK_TO_ONE" (click)="openDialogBeforeGoingToOne()" color="primary">
          {{ 'ABSCHLUSSSEITE.BACKTOONE' | translate }}
        </app-button>
      </div>
      <form
        style="display: none"
        ngNoForm
        id="kwhForm"
        method="post"
        [action]="kwsURL()"
        enctype="multipart/form-data"
        accept-charset="UTF-8"
      >
        <table>
          <tr>
            <td><label>Kundendaten:</label></td>
            <td>
              <input name="kundendaten" id="kundendaten" value="" />
            </td>
          </tr>
          <tr>
            <td>
              <label for="signatur">Signatur (wird automatisch berechnet):</label>
            </td>
            <td>
              <input type="text" name="signatur" id="signatur" value="" />
            </td>
          </tr>
        </table>
        <app-button type="submit" name="Submit" id="formSubmit">KWS submit</app-button>
      </form>
    </app-content-card>
  </div>

  <app-separator></app-separator>

  <app-content-card [maxWidth]="'unset'" class="center-content">
    <div class="meduplus">
      <div class="meduplus-text">
        <h3 appTranslationModal [translate]="'ABSCHLUSSSEITE.MEDUPLUS_HEADLINE'"></h3>
        <p appTranslationModal [translate]="'ABSCHLUSSSEITE.MEDUPLUS_FREE'"></p>
        <p appTranslationModal [translate]="'ABSCHLUSSSEITE.MEDUPLUS_CYBER'"></p>
        <p appTranslationModal [translate]="'ABSCHLUSSSEITE.MEDUPLUS_CLICK'"></p>
      </div>
      <app-button (click)="meduplusButton()"><span class="button-size-big">Jetzt starten</span></app-button>
    </div>
  </app-content-card>
</div>

<app-separator></app-separator>

<app-modal-dialog
  [showAcceptButton]="true"
  acceptButtonText="Zurück zu One"
  (accept)="isInfoBeforeLeavingVisible = false; redirectDashboardOne()"
  [isVisible]="isInfoBeforeLeavingVisible"
>
  <p>
    Ab sofort ist damit Ihr persönliches opta data banking Geschäftskonto startklar. 14 Tage nach Eröffnung stellen wir
    das Abrechnungskonto automatisch um. Denken Sie daher bitte daran das Online-Banking zeitnah zu aktivieren. Die
    entsprechenden Aktivierungsbriefe erhalten Sie per Post in den nächsten Tagen von uns.
  </p>
</app-modal-dialog>
