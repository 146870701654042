import { Component } from '@angular/core';
import { StepperComponent } from '../stepper/stepper.component';
import { ButtonComponent } from '../button/button.component';
import { RechtlicheHinweiseComponent } from '../rechtliche-hinweise/rechtliche-hinweise.component';
import { SeparatorComponent } from '../separator/separator.component';
import { ModalDialogComponent } from '../modal-dialog/modal-dialog.component';
import { SnackbarComponent } from '../snackbar/snackbar.component';

@Component({
  selector: 'app-test-konto-eroeffnen',
  standalone: true,
  imports: [
    SnackbarComponent,
    ButtonComponent,
    StepperComponent,
    RechtlicheHinweiseComponent,
    SeparatorComponent,
    ModalDialogComponent,
  ],
  templateUrl: './test-konto-eroeffnen.component.html',
  styleUrl: './test-konto-eroeffnen.component.scss',
})
export class TestKontoEroeffnenComponent {
  isModalVisible = false;
  isSnackbarVisible = false;
  notification = 'this is a Notification';

  buttonClicked() {
    //this.isModalVisible = true;
    this.isSnackbarVisible = true;
    setTimeout(() => {
      this.isSnackbarVisible = false;
    }, 5000);
  }

  closeModal() {
    this.isModalVisible = false;
  }

  acceptModal() {
    this.isModalVisible = false;
  }
}
