import { Component } from '@angular/core';
import { CheckBoxComponent } from '../checkbox/checkbox.component';

@Component({
  selector: 'app-rechtliche-hinweise',
  standalone: true,
  imports: [CheckBoxComponent],
  templateUrl: './rechtliche-hinweise.component.html',
  styleUrl: './rechtliche-hinweise.component.scss',
})
export class RechtlicheHinweiseComponent {}
