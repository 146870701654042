import { CommonModule, DatePipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from 'src/app/component/button/button.component';
import { ContentCardComponent } from 'src/app/component/content-card/content-card.component';
import { DefaultDialogBoxComponent } from 'src/app/component/dialog-boxes/default-dialog-box/default-dialog-box.component';
import { PdfViewBoxComponent } from 'src/app/component/pdf-view-box/pdf-view-box.component';
import { JurPerson, NatPerson, Status } from 'src/app/models/user';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/auth.service';
import { ButtonService } from 'src/app/services/btn.services';
import { DataService } from 'src/app/services/data.service';
import { QuestionService } from 'src/app/services/question.service';
import { environment } from 'src/environments/environment';
import { SeparatorComponent } from '../../../../component/separator/separator.component';
import { TranslationDirective } from '../../../../component/translata-modal/translation.directive';
import { QuestionTemplate } from '../question-template';
import { CheckBoxComponent } from '../../../../component/checkbox/checkbox.component';
import { InputtextComponent } from '../../../../component/inputtext/inputtext.component';

@Component({
  selector: 'app-dokumente',
  templateUrl: './dokumente.component.html',
  styleUrls: ['./dokumente.component.scss'],
  imports: [
    MatIconModule,
    CommonModule,
    NgIf,
    TranslationDirective,
    TranslateModule,
    FormsModule,
    NgFor,
    MatIcon,
    MatProgressSpinner,
    ReactiveFormsModule,
    ButtonComponent,
    DatePipe,
    ContentCardComponent,
    SeparatorComponent,
    CheckBoxComponent,
    InputtextComponent,
  ],
})
export class DokumenteComponent extends QuestionTemplate implements OnInit {
  override answerFormGroup = new FormGroup({
    eingetragenerName: new FormControl('', [Validators.required]),
    anrede: new FormControl('', [Validators.required]),
    titel: new FormControl(''),
    vorname: new FormControl('', [Validators.required]),
    nachname: new FormControl('', [Validators.required]),
    geburtsdatum: new FormControl('', [Validators.required]),
    bestätigt_document: new FormControl(false, [Validators.requiredTrue]),
    bestätigt_document1: new FormControl(false, [Validators.requiredTrue]),
    abweichungNachricht: new FormControl(null),
    steuerId: new FormControl(''),
    Handelsregisterauszug: new FormControl(false, [Validators.requiredTrue]),
    Gesellschaftsvertrag: new FormControl(false, [Validators.requiredTrue]),
    Gesellschafterliste: new FormControl(false, [Validators.requiredTrue]),
  });
  abweichungenGemeldet: Boolean;
  abweichendeAdresse: Boolean;
  documents: Document[];
  natPersonen: NatPerson[];
  jurPerson: JurPerson;
  isLoading = false;
  message: string = '';
  showAlert: boolean;
  isGBR: boolean;
  constructor(
    public override auth: AuthService,
    public override questionService: QuestionService,
    public override route: ActivatedRoute,
    public admin: AdminService,
    public dialog: MatDialog,
    private dataService: DataService,
    public btnService: ButtonService,
    private cdr: ChangeDetectorRef
  ) {
    super(auth, questionService, route);
    this.questionName = 'dokumente';
  }

  override ngOnInit() {
    super.ngOnInit();
    this._initUserData();
  }

  isDocumentRequired(isGBR: boolean, documentName: string, document?: any): boolean {
    const base64ContentPresent = document ? (document?.Base64 || document?.fileId ? true : false) : false;

    if (isGBR === true) {
      if (documentName === 'Gesellschaftsvertrag') {
        return true;
      } else if (
        (documentName === 'Transparenzregister' || documentName === 'Gesellschaftsregister') &&
        base64ContentPresent
      ) {
        return true;
      }
      return false;
    } else {
      if (
        documentName === 'Handelsregisterauszug' ||
        documentName === 'Gesellschaftsvertrag' ||
        documentName === 'Gesellschafterliste'
      ) {
        return true;
      } else if (documentName === 'Gesellschafterliste') {
        if (base64ContentPresent) {
          return true;
        }
      }
      return false;
    }
  }

  private _initUserData(): void {
    this._setInitialValues();
    this._filterDocuments();
    this._setFormControls();
  }

  private _setInitialValues(): void {
    this.isGBR = this.auth.isRechtsformGBR();
    this.natPersonen = this.auth.user.natPersonen;
    this.jurPerson = this.auth.user.JurPerson;
  }

  private _filterDocuments(): void {
    const documentTitles = [
      'Handelsregisterauszug',
      'Gesellschaftsvertrag',
      'Gesellschafterliste',
      'Transparenzregister',
      'Gesellschaftsregister',
    ];

    this.documents = documentTitles
      .map((title) => ({
        title,
        filename: '',
      }))
      .filter((doc) => this._isDocumentNeeded(doc.title));
  }

  private _isDocumentNeeded(title: string): boolean {
    const documents = this.jurPerson.Dokumente;
    const lastIndex = this.getLastIndex(documents[title]);
    const document = documents[title]?.[lastIndex];
    return this.isDocumentRequired(this.isGBR, title, document);
  }

  private _setFormControls(): void {
    const user = this.auth.user.natPersonen[this.auth.index];
    if (!user) {
      console.error('User not found', this.auth.user.natPersonen, this.auth.index);
      return;
    }

    this._updateFormControlBasedOnDocuments();
    const controls = this.answerFormGroup.controls;
    controls['eingetragenerName'].setValue(user.Firmenname);
    controls['anrede'].setValue(user.Person.Anrede);
    controls['titel'].setValue(user.Person.Titel);
    controls['vorname'].setValue(user.Person.Vorname);
    controls['nachname'].setValue(user.Person.Nachname);
    controls['geburtsdatum'].setValue(this._formatDate(user.Person.Geburtsdatum));
    controls['steuerId'].setValue(user.SteuerID);
  }

  private _updateFormControlBasedOnDocuments(): void {
    const documents = this.jurPerson.Dokumente;
    const controls = this.answerFormGroup.controls;
    ['Handelsregisterauszug', 'Gesellschaftsvertrag', 'Gesellschafterliste'].forEach((title) => {
      const index = this.getLastIndex(documents[title]);
      const doc = documents[title]?.[index];
      if (doc?.Base64 || doc?.fileId) {
        controls[title].setValue(true);
      }
    });

    if (this.auth.isRechtsformGBR()) {
      controls['Handelsregisterauszug'].setValue(true);
      controls['Gesellschafterliste'].setValue(true);
    }
  }

  private _formatDate(dateString: string): string {
    return dateString.split('-').reverse().join('.');
  }

  isabweichendeAdresse() {
    let natPerson = this.auth.user.natPersonen[this.auth.index];
    var Adresse = natPerson.Adresse;
    var Firmenanschrift = natPerson.Firmenanschrift;
    if (natPerson.Firmenanschrift.Strasse == '') {
      return false;
    }

    for (const element in Adresse) {
      if (Adresse[element] !== Firmenanschrift[element]) {
        return false;
      }
    }

    return true;
  }

  uploadAusweiskopieDocument(files: FileList | null, natPersonId: string): void {
    try {
      if (!files || files.length === 0) {
        return;
      }

      const file = files[0];
      const formData = new FormData();
      formData.append('file', file);

      const reader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        if (event.target?.result) {
          const base64Data = event.target.result as string;
          const base64EncodedData = base64Data.split(',')[1];

          const documentData = {
            filename: file.name,
            base64Data: base64EncodedData,
            timestamp: new Date(),
          };

          this.dataService.uploadAusweiskopieDocument(documentData, natPersonId).subscribe(
            () => {
              this.updateDocumentValue();
            },
            (error) => {
              console.error('Error during document upload:', error);
            }
          );
        }
      };

      reader.readAsDataURL(file);
    } catch (error) {
      console.error('Error during document upload:', error);
    }
  }

  close() {
    this.showAlert = false;
  }

  uploadRechtsDocument(files: FileList | null, Dokumententitel: string): void {
    try {
      if (!files || files.length === 0) {
        return;
      }

      const file = files[0];
      const sizeLimit = 10 * 1024 * 1024;

      if (file.size > sizeLimit) {
        this.message = 'Dateigröße darf 10 MB nicht überschreiten.';
        this.showAlert = true;
        return;
      }

      this.isLoading = true;
      const formData = new FormData();
      formData.append('file', file);

      const reader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        if (event.target?.result) {
          const base64Data = event.target.result as string;
          const base64EncodedData = base64Data.split(',')[1];

          const documentData = {
            Dokumententitel: Dokumententitel,
            filename: file.name,
            base64Data: base64EncodedData,
            timestamp: new Date(),
          };

          this.dataService.uploadRechtsDocument(documentData).subscribe({
            next: () => {
              this.isLoading = false;
              this.updateDocumentValue();
            },
            error: (error) => {
              this.isLoading = false;
              console.error('Error during document upload:', error);
            },
          });
        }
      };

      reader.readAsDataURL(file);
    } catch (error) {
      console.error('Error during document upload:', error);
    }
  }

  private base64toBlob(base64: string, contentType: string): Blob {
    const binary = atob(base64);
    const byteNumbers = new Array(binary.length);
    for (let i = 0; i < binary.length; i++) {
      byteNumbers[i] = binary.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  }

  openPdf(base64: string) {
    const blob = this.base64toBlob(base64, 'application/pdf');
    const url = window.URL.createObjectURL(blob);

    const dialogData: { pdfSrc: any } = { pdfSrc: url };

    this.dialog
      .open(PdfViewBoxComponent, {
        data: dialogData,
      })
      .afterClosed()
      .subscribe(() => {});
  }

  hasFileOrBase64(documentTitle: string): boolean {
    const documents = this.jurPerson?.Dokumente[documentTitle];
    const lastDocument = documents?.[documents.length - 1];
    return !!lastDocument?.fileId || !!lastDocument?.Base64;
  }

  handleClick(title: string) {
    const documents = this.jurPerson.Dokumente[title];
    if (!documents || documents.length === 0) return;

    const lastDocument = documents[documents.length - 1];
    if (lastDocument.fileId) {
      this.getrechtsdocument(lastDocument.fileId);
    } else if (lastDocument.Base64) {
      this.openPdf(lastDocument.Base64);
    }
  }

  getrechtsdocument(fileId) {
    this.isLoading = true;
    this.admin.getDocuments(fileId).subscribe({
      next: (blob) => {
        this.openPdfs(blob);
      },
      error: (error) => {
        this.isLoading = false;
        console.error('Error during document upload:', error);
      },
    });
  }

  openPdfs(blob: Blob) {
    const url = window.URL.createObjectURL(blob);

    const dialogData: { pdfSrc: any } = { pdfSrc: url };
    this.isLoading = false;
    this.dialog
      .open(PdfViewBoxComponent, {
        data: dialogData,
      })
      .afterClosed()
      .subscribe(() => {
        window.URL.revokeObjectURL(url);
      });
  }

  openPopUp() {
    this.abweichungenGemeldet = !this.abweichungenGemeldet;
  }

  closePopUp() {
    this.abweichungenGemeldet = false;
  }

  abweichungenSenden() {
    this.dialog
      .open(DefaultDialogBoxComponent, {
        data: {
          headerText: 'STAMMDATEN.ABWEICHUNGEN_POPUP_TEXT',
          confirm: 'okay',
          decline: 'zurück',
        },
      })
      .afterClosed()
      .subscribe(async (answer) => {
        if (answer == true) {
          this.admin
            .sendAbweichungEmail(this.auth.user._id, this.answerFormGroup.controls['abweichungNachricht'].value)
            .subscribe(() => {
              this.auth.changeStatus(this.auth.user._id, Status.inactive).subscribe(() => {
                window.location.href = environment.oneDashboardURL;
              });
            });
        }
      });
    this.closePopUp();
  }

  private updateDocumentValue() {
    this.auth.refreshUser().subscribe(() => {
      this.jurPerson = this.auth.user.JurPerson;
      this.natPersonen = this.auth.user.natPersonen;
      this.cdr.detectChanges();
      this._updateFormControlBasedOnDocuments();
    });
  }

  getLastIndex(arr) {
    if (!Array.isArray(arr) || arr.length === 0) {
      return -1;
    }

    return arr.length - 1;
  }
}

interface Document {
  title: string;
  filename: string;
  geburtsdatum?: string;
}
