import { Component } from '@angular/core';
import { ZubuchbarCardComponent } from '../zubuchbar-card/zubuchbar-card.component';

@Component({
  selector: 'app-zubuchbar-optionen',
  imports: [ZubuchbarCardComponent],
  templateUrl: './zubuchbar-optionen.component.html',
  styleUrl: './zubuchbar-optionen.component.scss',
})
export class ZubuchbarOptionenComponent {}
