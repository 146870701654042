<div class="lastpage">
  <div class="left-container">
    <img src="assets/img/Group.png" alt="cover" width="570" height="680" class="left-img" />
  </div>
  <div class="right-container">
    <div class="stepper">
      <app-stepper></app-stepper>
    </div>
    <p class="title">Geschafft!</p>

    <p class="text">
      Klasse. Damit ist ihr opta data banking Geschäftskonto eröffnet! Sie können Ihre Vertragsunterlagen in one
      jederzeit einsehen
    </p>

    <p class="text">Ihre neue IBAN ist direkt verfügbarl Sie lautet:</p>

    <div class="iban">
      <p class="iban-nummer">{{ iban }}</p>
      <app-copy-element></app-copy-element>
    </div>

    <p class="email">Wenn Sie noch Fragen haben, schreiben Sie uns eine E-Mail: optadatabanking&#64;bankhausbauer.de</p>
  </div>
</div>
