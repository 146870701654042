<div class="konto-container">
  <div class="left-container">
    <div class="stepper">
      <app-stepper></app-stepper>
    </div>
    <p class="title">Geschäftskonto eröffnen</p>
    <app-separator></app-separator>

    <app-rechtliche-hinweise></app-rechtliche-hinweise>

    <app-separator></app-separator>

    <div class="button">
      <app-button (click)="buttonClicked()">opta data Geschäftskonto eröffnen</app-button>
    </div>
  </div>
  <div class="right-container">
    <img src="assets/img/cover1.webp" alt="cover" width="570" height="680" class="right-img" />
  </div>
</div>

<app-modal-dialog
  [isVisible]="isModalVisible"
  [showAcceptButton]="false"
  [showCloseButton]="true"
  [acceptButtonText]="'accept'"
  [closeButtonText]="'close'"
  (close)="closeModal()"
  (accept)="acceptModal()"
>
  <div class="modal-container">
    <h2>Modal Title</h2>
    <p>This is the modal's content</p>
  </div>
</app-modal-dialog>

<app-snackbar [notification]="notification" [isVisible]="isSnackbarVisible"></app-snackbar>
