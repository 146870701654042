import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { jwtDecode } from 'jwt-decode';
import { KeycloakService } from 'keycloak-angular';
import { DecodedToken } from 'src/app/models/accessToken.model';
import { environment } from 'src/environments/environment';
import { Status } from './models/user';
import { AdminService } from './services/admin.service';
import { AuthService } from './services/auth.service';
import { TokenService } from './services/jwttoken.service';
import { productMatrixService } from './services/product-matrix.service';

import { FooterComponent } from './pages/footer-navigation/footer/footer.component';
import { WaveBackgroundComponent } from './component/wave-background/wave-background.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [NgIf, RouterOutlet, FooterComponent, WaveBackgroundComponent],
})
export class AppComponent {
  enterLandingPage: boolean = false;
  institutionId: string | null = null;
  constructor(
    private translate: TranslateService,
    private auth: AuthService,
    private keycloakService: KeycloakService,
    private tokenservice: TokenService,
    private adminService: AdminService,
    private productMatrix: productMatrixService
  ) {
    this.initializeApp();
  }

  private async initializeApp(): Promise<void> {
    try {
      const token = await this.keycloakService.getToken();
      this.auth.setKeycloakToken(token);
      const parsedToken = this.decodeToken(token);

      const { institutions, sub: UUID, email } = parsedToken;

      const institutionIdPromise = new Promise<string | null>((resolve) => {
        const subscription = this.adminService.getInstitutionId$().subscribe((id) => {
          if (id) {
            this.institutionId = id;
            subscription.unsubscribe();
            resolve(id);
          }
        });

        setTimeout(() => {
          subscription.unsubscribe();
          resolve(null);
        }, 2000);
      });

      this.institutionId = await institutionIdPromise;

      if (this.institutionId) {
        this.handleLogin(UUID, email, institutions, this.institutionId);
      } else {
        this.handleLogin(UUID, email, institutions, null); // Proceed without institutionId
      }
    } catch (error) {
      this.redirectToDashboard();
    }
  }

  private decodeToken(token: string): DecodedToken {
    return jwtDecode<DecodedToken>(token);
  }

  private handleLogin(UUID: string, email: string, institutions: any, institutionID): void {
    this.auth.login(UUID, email, institutions, institutionID).subscribe({
      next: (user) => {
        this.processUser(user);
        this.productMatrix
          .getBerufsGruppe(this.auth.user.natPersonen[this.auth.index].BranchenInfo.Art)
          .subscribe((berufsGruppe) => {
            this.auth.setBerufsGruppe(berufsGruppe);
          });
      },
      error: () => this.redirectToDashboard(),
    });
  }

  private processUser(user: any): void {
    if (user) {
      this.setupTranslation();
      this.enterLandingPage = this.isUserAllowed(user.status);
    } else {
      this.enterLandingPage = false;
    }
  }

  private setupTranslation(): void {
    this.translate.addLangs(['odfin']);
    this.translate.setDefaultLang('odfin');
  }

  private isUserAllowed(status: Status): boolean {
    if (status === Status.active || status === Status.finish) {
      return true;
    }
    this.redirectToDashboard();
    return false;
  }

  private redirectToDashboard(): void {
    window.location.href = environment.oneDashboardURL;
  }
}
