import { computed, DestroyRef, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class WaveService {
  isWaveActive = signal(false);
  einsichtStep = signal(0);
  currentRoute = signal('');
  isEinsichtWaveActivation = computed(() => 'einsicht'.includes(this.currentRoute()) && this.einsichtStep() === 4);

  private router = inject(Router);
  private destroyRef = inject(DestroyRef);
  diableWaveOnRoute = ['overview', 'bank-wahl', 'einsicht', 'admin', 'info'];
  constructor() {
    this.router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute.set(event.url.split('/')[1]);
        this.isWaveActive.set(this.activeOnRoute(this.currentRoute()));
      }
    });
  }

  activeOnRoute(urlCleaned: string) {
    return !this.diableWaveOnRoute.includes(urlCleaned);
  }
}
