<div>
  <div class="header-container">
    <h1 class="ps-5">Datenschutzerklärung</h1>
    <p class="ps-5">optadata.de</p>
  </div>
  <div class="m-5">
    <a
      href="https://www.optadata-gruppe.de/fileadmin/user_upload/datenschutz/Anlage_U_Datenschutzerklaerung_Artikel_13.pdf"
      target="_blank"
      >Informationsschreiben nach Artikel 13</a
    ><br /><br />

    <a
      href="https://www.optadata-gruppe.de/fileadmin/user_upload/datenschutz/odFIN_Artikel_14_Stand_12-2021.pdf"
      target="_blank"
      >Informationsschreiben nach Artikel 14</a
    >

    <h2 class="mt-5">1. Wer ist verantwortlich für die Datenerfassung auf dieser Website?</h2>
    <p>
      Verantwortlicher im Sinne der Datenschutz-Grundverordnung, sonstiger in den Mitgliedstaaten der Europäischen Union
      geltenden Datenschutzgesetze und anderer Bestimmungen mit datenschutzrechtlichem Charakter ist die
    </p>

    <p>
      opta data Finance GmbH<br />
      Berthold-Beitz-Boulevard 461<br />
      D-45141 Essen
    </p>

    <p>
      Telefon: 0201 3196-0<br />
      Fax: 0201 3196-222<br />
      E-Mail:
      <a href="mailto:service@optadata-gruppe.de">service&#64;optadata-gruppe.de</a>
    </p>

    <h2 class="mt-5">Datenschutzbeauftragter</h2>

    <p>Wir haben für unser Unternehmen einen Datenschutzbeauftragten bestellt.</p>

    <p>
      Datenschutz Kramer und Kramer GmbH<br />
      Büro für Datenschutz und Datensicherheit<br />
      Elsternweg 24<br />
      D-42555 Velbert
    </p>

    <p>
      Telefon: 02052 9289766<br />
      E-Mail:
      <a href="mailto:info@datenschutz-kramer.de">info&#64;datenschutz-kramer.de</a>
    </p>

    <h2 class="mt-5">2. Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck von deren Verwendung</h2>

    <h4 class="mt-3">a) Beim Besuch der Website</h4>

    <p>
      Beim Aufrufen unserer Website werden durch den auf Ihrem Endgerät zum Einsatz kommenden Browser automatisch
      Informationen an den Server unserer Website gesendet. Diese Informationen werden temporär in einem sog. Logfile
      gespeichert. Folgende Informationen werden dabei ohne Ihr Zutun erfasst und bis zur automatisierten Löschung
      gespeichert:
    </p>

    <ul class="list-group-list">
      <li>anonymisierte IP-Adresse des anfragenden Rechners,</li>
      <li>Datum und Uhrzeit des Zugriffs,</li>
      <li>Name und URL der abgerufenen Datei,</li>
      <li>Website, von der aus der Zugriff erfolgt (Referrer-URL),</li>
      <li>verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers.</li>
    </ul>

    <br />

    <p>Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet:</p>

    <ul class="list-group-list">
      <li>Gewährleistung eines reibungslosen Verbindungsaufbaus der Website,</li>
      <li>Gewährleistung einer komfortablen Nutzung unserer Website,</li>
      <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
      <li>zu weiteren administrativen Zwecken.</li>
    </ul>

    <p>
      Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Unser berechtigtes Interesse
      folgt aus oben aufgelisteten Zwecken zur Datenerhebung. In keinem Fall verwenden wir die erhobenen Daten zu dem
      Zweck, Rückschlüsse auf Ihre Person zu ziehen.
    </p>

    <h4 class="mt-3">b) Bei Anmeldung für unseren Newsletter</h4>

    <p>
      Sofern Sie nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrücklich eingewilligt haben, verwenden wir Ihre E-Mail-Adresse
      dafür, Ihnen regelmäßig unseren Newsletter zu übersenden.
    </p>
    <p>
      Der Einsatz des Versanddienstleisters, Durchführung statistischer Erhebungen und Analysen sowie Protokollierung
      des Anmeldeverfahrens erfolgt durch das Pardot Marketing Automation System ("Pardot MAS") auf Grundlage unserer
      berechtigten Interessen gemäß Art. 6 Abs. 1 lit. f DSGVO. Pardot wird die hier bezeichneten Daten nicht an Dritte
      weiterleiten. Pardot MAS ist ein Analysedienst der Pardot LLC, 950 E. Paces Ferry Rd. Suite 3300 Atlanta, GA
      30326, USA ("Pardot"). Pardot verwendet sogenannte „Cookies", Textdateien, die auf Ihrem Computer gespeichert
      werden und die eine Analyse der Benutzung des Newsletters durch Sie ermöglichen. Indem Sie bei erstmaliger Nutzung
      unserer Website über die Nutzung von Cookies aufgeklärt werden und die Cookie-Einstellungen selbst vornehmen
      können, willigen Sie in den Einsatz von Cookies ein.
    </p>
    <p>
      Sie können Ihre Einwilligung zum Empfang unseres Newsletters jederzeit widerrufen. Die Abmeldung hierfür kann
      unter dem Link im Newsletter mit sofortiger Wirkung oder durch Ihre Mitteilung per E-Mail an
      <a href="mailto:service@optadata-gruppe.de">service&#64;optadata-gruppe.de</a>
      erfolgen. Bitte beachten Sie, dass bis zur Bearbeitung und Umsetzung eines Widerrufes des Newsletters per E-Mail
      bis zu 7 Werktagen vergehen können und bis dahin ein erneuter Newsletter versandt werden kann. Soweit wir Ihre
      personenbezogenen Daten zur Direktwerbung nutzen, können Sie dem gemäß Art. 21 DSGVO jederzeit durch Mitteilung an
      uns widersprechen.
    </p>
    <p>
      Die Einwilligung in die Nutzung von Cookies wird über Usercentrics sichergestellt. Die Usercentrics GmbH,
      Sendinger Str. 7, 80331 München erhebt dabei nur anonymisierte Daten zu statistischen Zwecken.
    </p>

    <h4 class="mt-3">c) Bei Nutzung unseres Kontaktformulars</h4>

    <p>
      Bei Fragen jeglicher Art bieten wir Ihnen die Möglichkeit, mit uns über ein auf der Website bereitgestelltes
      Formular Kontakt aufzunehmen. Dabei ist die Angabe einer gültigen E-Mail-Adresse erforderlich, damit wir wissen,
      von wem die Anfrage stammt und um diese beantworten zu können. Weitere Angaben können freiwillig getätigt werden.
    </p>
    <p>
      Die Datenverarbeitung zum Zwecke der Kontaktaufnahme mit uns erfolgt nach Art. 6 Abs. 1 S. 1 lit. a DSGVO auf
      Grundlage Ihrer freiwillig erteilten Einwilligung.
    </p>

    <h4 class="mt-3">d) Nutzung von Event und Ticketmanagement</h4>

    <p>
      Auf unserer Webseite bieten wir Ihnen die Möglichkeit, die Teilnahme an Events zu buchen. Hierzu verwenden wir die
      technische Lösung „Eventbrite“, welche von der Eventbrite, Inc., 155 5th Street, Floor 7, San Francisco, CA 94103,
      USA betrieben wird.
    </p>
    <p>
      Eventbrite hat eine Vertretung zum Zwecke der europäischen Datenschutzgesetzgebung. Dies ist die Eventbrite NL BV
      mit Sitz in Silodam 402, 1013AW, Amsterdam, Niederlande.
    </p>
    <p>Wenn Sie sich für ein Event anmelden, werden Sie auf die Webseite von Eventbrite weitergeleitet.</p>
    <p>
      Eventbrite erfasst personenbezogenen Daten, wenn Sie solche Informationen freiwillig im Rahmen einer Anmeldung zu
      einer unserer Veranstaltungen bereitstellen übermittelt Eventbrite diese Daten anschließend an uns als
      Veranstalter. Um sich bei Eventbrite für ein Event anzumelden, müssen Sie folgende Daten an Eventbrite Inc.
      übermitteln: Nachname, Vorname, E-Mail-Adresse, Unternehmen, Geschäftsanschrift, Zahlungsinformationen, Ticketart
      Event-ID, IP-Adresse, Merkmale des Zugriffsgerätes und/oder des Browsers
    </p>
    <p>
      Welche Daten Eventbrite bei diesem Webseiten-Aufruf speichert und verarbeitet, legt Eventbrite als
      Verantwortlicher in der eigenen Datenschutzerklärung dar. Die Datenschutzerklärung von Eventbrite finden Sie hier:
      <a
        href="https://www.eventbrite.de/support/articles/de/Troubleshooting/datenschutzrichtlinien-von-eventbrite?lg=de"
        >https://www.eventbrite.de/support/articles/de/Troubleshooting/datenschutzrichtlinien-von-eventbrite?lg=de</a
      >
    </p>
    <p>
      Die Eventbrite Inc. nimmt an dem EU-US-Datenschutzschild-Rahmenprogramm des US-Handelsministeriums und der
      Europäischen Kommission in Bezug auf die Erhebung, Nutzung und Aufbewahrung personenbezogener Daten aus den
      Mitgliedstaaten des Europäischen Wirtschaftsraums teil.
    </p>
    <p>
      Hier erhalten Sie Informationen dazu, welche Daten die Eventbrite Inc. im Rahmen des
      EU-US-Datenschutzschild-Rahmenprogramms erhebt, verarbeitet und nutzt und für welche Zwecke dies geschieht:
      <a
        href="https://www.eventbrite.de/support/articles/de/Troubleshooting/informationen-zum-eu-us-datenschutzschild?lg=de"
        >https://www.eventbrite.de/support/articles/de/Troubleshooting/informationen-zum-eu-us-datenschutzschild?lg=de</a
      >.
    </p>
    <p>
      Des Weiteren finden Sie über den nachfolgenden Link weitere Informationen hinsichtlich der Einhaltung der
      europäischen Datenschutzregelungen durch Eventbrite:
      <a
        href="https://www.eventbrite.de/support/articles/de/Troubleshooting/datenverarbeitungsnachtrag-fuer-veranstalter"
        >https://www.eventbrite.de/support/articles/de/Troubleshooting/datenverarbeitungsnachtrag-fuer-veranstalter</a
      >
    </p>
    <p>
      Weitere Informationen dazu, wie Eventbrite Inc. personenbezogene Daten verwendet, erhalten Sie in der
      Datenschutzrichtlinie von Eventbrite:
      <a
        href="https://www.eventbrite.de/support/articles/de/Troubleshooting/datenschutzrichtlinien-von-eventbrite?lg=de"
        >https://www.eventbrite.de/support/articles/de/Troubleshooting/datenschutzrichtlinien-von-eventbrite?lg=de</a
      >.
    </p>
    <p>
      Als Veranstalter erhalten wir von Eventbrite Zugriff auf die oben aufgeführten Daten der Teilnehmer des gebuchten
      Events. Die Daten verwenden wir für die Zwecke der Vor- und Nachbereitung der jeweils gebuchten Events. Zudem
      erhalten registrierte Teilnehmer vor und nach dem gebuchten Event Informationen zum gebuchten Event und unsere
      Kontaktmöglichkeiten per E-Mail zugesendet.
    </p>
    <p>
      Die Verarbeitung der Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO (Verarbeitung zur Erfüllung eines
      Vertrags).
    </p>
    <p>
      Die Nutzer erhalten nach dem Event Informationen per E-Mail zugesendet und werden von uns für Zwecke des Hinweises
      auf ähnliche Events in der Zukunft kontaktiert. Diese Verarbeitungen sind nach Art. 6 Abs. 1 lit. b) DSGVO
      gerechtfertigt. Sie haben jederzeit die Möglichkeit, der Nutzung dieser Daten für werbliche Zwecke für die Zukunft
      zu wiedersprechen, indem Sie Ihrem Wunsch über die Zugangsdaten auf die Plattform www.eventbrite.de über die Sie
      gebucht haben auch widerrufen. Sollten Sie außerhalb von Eventbrite Veranstaltungen bei uns gebucht haben schicken
      Sie Ihren Widerruf an
      <a href="mailto:datenschutzmanagement@optadata-gruppe.de">datenschutzmanagement&#64;optadata-gruppe.de</a>.
      Darüber hinaus sind wir gesetzlich verpflichtet Daten wie Rechnungen, Verträge und andere buchhalterisch relevante
      Informationen für den gesetzlich vorgegebenen Zeitraum zu speichern. Die für diesen Zweck erfolgenden
      Verarbeitungen erfolgen auf Grundlage von Art. 6 Abs. 1 lt. c) DSGVO i.V.m §147 AO und 257 HGB.
    </p>

    <h2 class="mt-5">3. Weitergabe von Daten</h2>

    <p>
      Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken findet
      nicht statt. Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:
    </p>

    <ul class="list-group-list">
      <li>Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche Einwilligung dazu erteilt haben,</li>
      <li>
        die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur Geltendmachung, Ausübung oder Verteidigung von
        Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges
        Interesse an der Nichtweitergabe Ihrer Daten haben,
      </li>
      <li>
        für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine gesetzliche Verpflichtung
        besteht, sowie
      </li>
      <li>
        dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für die Abwicklung von Vertragsverhältnissen
        mit Ihnen erforderlich ist.
      </li>
    </ul>

    <h2 class="mt-5">4. Cookies</h2>

    <p>
      Für den Betrieb der Webseite nutzen wir Cookies. Dies sind Dateien, die ihr Browser bei Besuch unserer Webseite
      auf ihrem Endgerät speichert. Sie dienen der Speicherung von Daten Ihres Besuchs und zur Wiedererkennung wie auch
      zur statistischen Erfassung, Verbesserung und Gewährleistung des Betriebes unserer Webseite.
    </p>
    <p>
      Rechtsgrundlage hierfür ist § 6 Abs. 1 lit f DSGVO. Temporäre Cookies werden nach dem Verlassen der Webseite
      gelöscht. Die meisten Browser akzeptieren Cookies automatisch. Sie können Ihren Browser jedoch so konfigurieren,
      dass keine Cookies auf Ihrem Computer gespeichert werden oder stets ein Hinweis erscheint, bevor ein neuer Cookie
      angelegt wird. Die vollständige Deaktivierung von Cookies kann jedoch dazu führen, dass Sie nicht alle Funktionen
      unserer Website nutzen können.
    </p>

    <h2 class="mt-5">5. Analyse-Tools</h2>

    <h4 class="mt-3">Einsatz der Usercentrics Consent Management Plattform</h4>

    <p>
      Auf unserer Webseite setzen wir die Usercentrics Consent Management Plattform („Usercentrics“) ein, um Sie über
      die Technologien zu informieren, die wir auf unserer Website verwenden, sowie Ihre Einwilligung in die
      Verarbeitung Ihrer personenbezogenen Daten gem. Art. 6 Abs. 1 lit. a durch diese Technologien einzuholen, zu
      verwalten und zu dokumentieren. Dazu nutzt Usercentrics eine Checkbox, die beim erstmaligen Betreten der Webseite
      erscheint. Dies ist gemäß Art. 6 Abs. 1 S. 1 lit. c DSGVO zur Erfüllung unserer rechtlichen Verpflichtung gemäß
      Art. 7 Abs. 1 DSGVO erforderlich, Ihre Einwilligung in die Verarbeitung Ihrer personenbezogenen Daten nachweisen
      zu können, der wir unterliegen. Informationen zu den einzelnen Cookies bzw. Technologien finden Sie direkt bei den
      entsprechenden Schaltflächen in der Checkbox.
    </p>
    <p>
      Der Zustimmungsverwaltungsdienst Usercentrics ist ein Angebot der Usercentrics GmbH, Rosental 4, 80331 München,
      die in unserem Auftrag Ihre Daten verarbeitet.
    </p>
    <p>
      Bei Besuch unserer Webseite speichert der Webserver von Usersentrics ein sogenanntes Server-Logfile, das auch Ihre
      anonymisierte IP-Adresse, Datum und Uhrzeit des Besuchs, Geräte- und Browser-Informationen sowie Informationen zu
      Ihrem Einwilligungsverhalten enthält. Ihre Daten werden nach drei Jahren gelöscht, sofern Sie nicht ausdrücklich
      in eine weitere Nutzung Ihrer Daten gemäß Art. 6 Abs. 1 S. 1 lit. a DSGVO eingewilligt haben oder wir uns eine
      darüberhinausgehende Datenverwendung vorbehalten, die gesetzlich erlaubt ist und über die wir Sie in dieser
      Erklärung informieren.
    </p>
    <p>
      Wir nutzen auf unserer Webseite den Webseiten-Analysedienst für Webseiten von Google Analytics, ein
      Webanalysedienst der Google Inc. (<a href="https://about.google/" target="_blank">about.google</a>) (1600
      Amphitheatre Parkway, Mountain View, CA 94043, USA; im Folgenden „Google“). In diesem Zusammenhang werden
      pseudonymisierte Nutzungsprofile erstellt und Cookies (siehe unter Ziff. 4) verwendet. Die durch den Cookie
      erzeugten Informationen über Ihre Benutzung dieser Website wie:
    </p>

    <ul class="list-group-list">
      <li>Browser-Typ/-Version,</li>
      <li>verwendetes Betriebssystem,</li>
      <li>Cookie-ID,</li>
      <li>Referrer-URL (die zuvor besuchte Seite),</li>
      <li>Hostname des zugreifenden Rechners (IP-Adresse),</li>
      <li>Uhrzeit der Serveranfrage,</li>
    </ul>

    <p>
      werden an einen Server von Google in den USA übertragen und dort gespeichert. Die Informationen werden verwendet,
      um die Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere
      mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu Zwecken der Marktforschung und
      bedarfsgerechten Gestaltung dieser Internetseiten zu erbringen. Auch werden diese Informationen gegebenenfalls an
      Dritte übertragen, sofern dies gesetzlich vorgeschrieben ist oder soweit Dritte diese Daten im Auftrag
      verarbeiten. Es wird in keinem Fall Ihre IP-Adresse mit anderen Daten von Google zusammengeführt. Die IP-Adressen
      werden anonymisiert, so dass eine Zuordnung nicht möglich ist (IP-Masking).
    </p>

    <p>
      Sie können die Installation der Cookies durch eine entsprechende Einstellung der Browser-Software verhindern; wir
      weisen jedoch darauf hin, dass in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website
      vollumfänglich genutzt werden können.
    </p>

    <p>
      Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen
      Daten (inkl. Ihrer IP-Adresse) sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie ein
      Browser-Add-on herunterladen und installieren (<a
        href="https://tools.google.com/dlpage/gaoptout?hl=de"
        target="_blank"
        >tools.google.com/dlpage/gaoptout?hl=de</a
      >).
    </p>

    <p>
      Weitere Informationen zum Datenschutz im Zusammenhang mit Google Analytics finden Sie etwa in der Google
      Analytics-Hilfe (<a href="https://support.google.com/analytics/answer/6004245?hl=de" target="_blank"
        >support.google.com/analytics/answer/6004245?hl=de</a
      >)
    </p>

    <p>
      Bei Nutzung des Dienstes Google Adwords Conversion Tracking wird, sofern Sie über eine Anzeige bei Google auf
      diese Seite gelangt sind, ein Cookie auf Ihrem Gerät gesetzt. Dies dient der Analyse und statistischen Auswertung
      der Nutzung der Webseite. Das Cookie ist 30 Tage gültig. Eine Identifizierung der Person erfolgt nicht, allerdings
      die Beobachtung, ob der Nutzer über eine Google Werbeanzeige auf die Seite gelangt ist.
    </p>

    <p>
      Diese Nutzung können Sie verhindern, indem Sie in den Einstellungen Ihres Browsers das Setzen entsprechender
      Cookies ablehnen.
    </p>

    <h4 class="mt-3">Google Tag Manager</h4>

    <p>
      Wir verwenden den Dienst “Google Tag Manager”. Der Tag Manager ist ein Tool zur Verwaltung von sog. Tags bzw.
      Stichworten, welche beim Tracking im Online Marketing eingesetzt werden können. Der Tag Manager selber verarbeitet
      dabei keine personenbezogenen Daten, da er rein der Verwaltung von anderen Diensten – bspw. Google Analytics, etc.
      – dient. Weitere Informationen zum Tag Manager finden Sie unter:
      <a href="https://marketingplatform.google.com/intl/de/about/analytics/tag-manager/use-policy/" target="_blank"
        >marketingplatform.google.com/intl/de/about/analytics/tag-manager/use-policy</a
      >
    </p>

    <h4 class="mt-3">Nutzung der SalesViewer®-Technologie</h4>

    <p>
      Auf dieser Webseite werden mit der SalesViewer®-Technologie der SalesViewer® GmbH auf Grundlage berechtigter
      Interessen des Webseitenbetreibers (Art. 6 Abs.1 lit.f DSGVO) Daten zu Marketing-, Marktforschungs- und
      Optimierungszwecken gesammelt und gespeichert.
    </p>
    <p>
      Hierzu wird ein javascript-basierter Code eingesetzt, der zur Erhebung unternehmensbezogener Daten und der
      entsprechenden Nutzung dient. Die mit dieser Technologie erhobenen Daten werden über eine nicht rückrechenbare
      Einwegfunktion (sog. Hashing) verschlüsselt. Die Daten werden unmittelbar pseudonymisiert und nicht dazu benutzt,
      den Besucher dieser Webseite persönlich zu identifizieren.
    </p>
    <p>
      Die im Rahmen von Salesviewer gespeicherten Daten werden gelöscht, sobald sie für ihre Zweckbestimmung nicht mehr
      erforderlich sind und der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen.
    </p>
    <p>
      Der Datenerhebung und -speicherung kann jederzeit mit Wirkung für die Zukunft widersprochen werden, indem Sie
      bitte diesen Link
      <a href="https://www.salesviewer.com/opt-out" target="_blank">https://www.salesviewer.com/opt-out</a>
      anklicken, um die Erfassung durch SalesViewer® innerhalb dieser Webseite zukünftig zu verhindern. Dabei wird ein
      Opt-out-Cookie für diese Webseite auf Ihrem Gerät abgelegt. Löschen Sie Ihre Cookies in diesem Browser, müssen Sie
      diesen Link erneut klicken.
    </p>

    <h2 class="mt-5">6. Plugins und Tools</h2>

    <p>
      Auf Basis des Art. 6 Abs. 1 S. 1 lit f DSGVO werden Plug-Ins, Verlinkungen und Buttons zu Social Media Webseiten
      zum Zwecke der Werbung und Information eingesetzt. Durch den Einbau dieser Elemente werden bei Aufruf unserer
      Seite Daten an die entsprechenden Social Media Anbieter übermittelt, ggf. auch in Drittstaaten. Diese Daten werden
      von den Anbietern gespeichert und verarbeitet; insbesondere kann ein Anbieter, sofern Sie gleichzeitig bei den
      Social Media Anbietern eingeloggt sind, den Besuch unserer Webseite erkennen. Auch wenn Sie Beiträge unserer
      Webseite im Social Networt „Teilen“ oder sonst weitergeben („Like“), wird dies dem Anbieter übermittelt und von
      diesem gespeichert, ggf. auch zu Ihrem Nutzerprofil. Die Sozialen Netzwerke nutzen diese Daten zu geschäftlichen
      Zwecken, Marketing, Werbung und weiteren Zwecken, insbesondere auch zum Anlegen und Erstellen von Profilen über
      Sie und für die Bereitstellung personalisierter Werbung. Zweck und Umfang der Datenerhebung und die weitere
      Verarbeitung und Nutzung der Daten durch Facebook sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten
      zum Schutz Ihrer Privatsphäre entnehmen Sie bitte den Datenschutzhinweisen von Facebook (<a
        href="https://www.facebook.com/about/privacy/"
        target="_blank"
        >www.facebook.com/about/privacy</a
      >).
    </p>

    <h4 class="mt-3">YouTube mit erweitertem Datenschutz</h4>

    <p>
      Diese Website bindet Videos der YouTube ein. Betreiber der Seiten ist die Google Ireland Limited („Google“),
      Gordon House, Barrow Street, Dublin 4, Irland.
    </p>
    <p></p>
    <p>
      Wir nutzen YouTube im erweiterten Datenschutzmodus. Dieser Modus bewirkt laut YouTube, dass YouTube keine
      Informationen über die Besucher auf dieser Website speichert, bevor diese sich das Video ansehen. Die Weitergabe
      von Daten an YouTube-Partner wird durch den erweiterten Datenschutzmodus hingegen nicht zwingend ausgeschlossen.
      So stellt YouTube – unabhängig davon, ob Sie sich ein Video ansehen – eine Verbindung zum Google
      DoubleClick-Netzwerk her. Sobald Sie ein YouTube-Video auf dieser Website starten, wird eine Verbindung zu den
      Servern von YouTube hergestellt. Dabei wird dem YouTube-Server mitgeteilt, welche unserer Seiten Sie besucht
      haben. Wenn Sie in Ihrem YouTube-Account eingeloggt sind, ermöglichen Sie YouTube, Ihr Surfverhalten direkt Ihrem
      persönlichen Profil zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem YouTube-Account ausloggen.
    </p>
    <p>
      Des Weiteren kann YouTube nach Starten eines Videos verschiedene Cookies auf Ihrem Endgerät speichern oder
      vergleichbare Wiedererkennungstechnologien (z. B. Device-Fingerprinting) einsetzen. Auf diese Weise kann YouTube
      Informationen über Besucher dieser Website erhalten. Diese Informationen werden u. a. verwendet, um
      Videostatistiken zu erfassen, die Anwenderfreundlichkeit zu verbessern und Betrugsversuchen vorzubeugen.
      Gegebenenfalls können nach dem Start eines YouTube-Videos weitere Datenverarbeitungsvorgänge ausgelöst werden, auf
      die wir keinen Einfluss haben. Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden Darstellung
      unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
      Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von
      Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar. Weitere Informationen über Datenschutz bei
      YouTube finden Sie in deren Datenschutzerklärung unter: policies.google.com/privacy?hl=de.
    </p>

    <h4 class="mt-3">Zendesk Helpdesk</h4>

    <p>
      Wir verwenden Zendesk für die Bereitstellung eines Helpdesk sowie Ticketsystem, um eingehende Anfragen von Nutzern
      und Kunden zu bearbeiten.
    </p>

    <p>Weitere Informationen finden Sie hier:</p>

    <div>
      <a href="https://www.zendesk.de/company/customers-partners/privacy-policy/" target="_blank"
        >Datenschutzbestimmungen des Datenverarbeiters</a
      >
    </div>
    <div>
      <a href="https://www.youronlinechoices.eu/" target="_blank">Opt-Out Link</a>
    </div>
    <div>
      <a href="https://www.zendesk.de/company/agreements-and-terms/cookie-policy/" target="_blank"
        >Cookie-Richtlinie des Verarbeiters</a
      >
    </div>

    <h2 class="mt-5">7. Betroffenenrechte</h2>

    <p>Sie haben das Recht:</p>

    <ul class="list-group-list">
      <li>
        gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen. Insbesondere
        können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von
        Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen
        eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines
        Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen
        einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen Informationen zu
        deren Einzelheiten verlangen;
      </li>
      <li>
        gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten
        personenbezogenen Daten zu verlangen;
      </li>
      <li>
        gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht
        die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer
        rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder
        Verteidigung von Rechtsansprüchen erforderlich ist;
      </li>
      <li>
        gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die
        Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung
        ablehnen
      </li>
      <li>
        und wir die Daten nicht mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von
        Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;
      </li>
      <li>
        gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten,
        gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu
        verlangen;
      </li>
      <li>
        gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen. Dies hat zur
        Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr
        fortführen dürfen und
      </li>
      <li>
        gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die
        Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Kanzleisitzes wenden
      </li>
      <li>
        gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür
        Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben oder sich der Widerspruch gegen Direktwerbung
        richtet. Im letzteren Fall haben Sie ein generelles Widerspruchsrecht, das ohne Angabe einer besonderen
        Situation von uns umgesetzt wird. Sie haben die Möglichkeit, den Widerspruch telefonisch, per E-Mail, per
        Telefax oder an unsere zu Beginn dieser Datenschutzerklärung aufgeführte Postadresse unserer Kanzlei formlos
        mitzuteilen.
      </li>
    </ul>

    <h2 class="mt-5">8. Datensicherheit</h2>

    <p>
      Zur Gewährung der Datensicherheit erfolgt die Übertragung der Inhalte unserer Webseite verschlüsselt nach dem SSL
      Verfahren gemäß dem Stand der Technik. Zur Sicherung der Daten werden von uns und den beauftragten Dienstleistern,
      mit denen entsprechende vertragliche Vereinbarungen getroffen wurden, geeignete Maßnahmen nach dem Stand der
      Technik, insbesondere zur Beschränkung des Zugangs zu den Daten, zum Schutz gegen Veränderungen und Verlust, und
      zur Vertraulichkeit gemäß dem Stand der Technik eingesetzt.
    </p>

    <h2 class="mt-5">9. Stand und Aktualisierung dieser Datenschutzerklärung</h2>

    <p>
      Diese Datenschutzerklärung hat den Stand vom 14.12.2021. Wir behalten uns vor, die Datenschutzerklärung zu
      gegebener Zeit zu aktualisieren, um den Datenschutz zu verbessern und/oder anzupassen.
    </p>

    <h4 class="mt-3">Transparenzgebote</h4>

    <p>
      Aufgrund einer Forderung aus der am 25. Mai 2018 in Kraft getretenen EU Datenschutz-Grundverordnung (DSGVO) sind
      wir, die opta data Finance GmbH Berthold-Beitz-Boulevard 461, 45141 Essen verpflichtet, Sie nach Artikel 13 der
      DSGVO ausführlich über die Verarbeitung Ihrer personenbezogenen Daten auf unserer Webseite (nachfolgend nur noch
      „Daten“ genannt) aufzuklären. Dieser Verpflichtung kommen wir gerne nach. Nachfolgend erläutern wir, welche Daten
      wir von Ihnen zu welchen Zwecken verarbeiten und welche Rechte Sie diesbezüglich haben.
    </p>

    <h4 class="mt-3">Zwecke der Verarbeitung</h4>

    <p>
      Die Daten, die sie uns freiwillig über unsere Webseite zur Verfügung stellen, verwenden wir für folgende Zwecke:
    </p>

    <ul class="list-group-list">
      <li>nach Artikel 6 Abs. 1 a) der DSGVO auf der Basis des von Ihnen freiwillig ausgefüllten Kontaktformulars</li>
      <ul>
        <li>zur Kontaktaufnahme mit Ihnen</li>
        <li>zur Beantwortung konkreter Anfragen</li>
        <li>bei Anmeldung für unseren Newsletter</li>
        <li>zur Vertragsanbahnung</li>
      </ul>
    </ul>

    <h4 class="mt-3">Kategorien der verarbeiteten Daten</h4>

    <ul class="list-group-list">
      <li>Firmenname</li>
      <li>Unternehmensform</li>
      <li>Kundennummer</li>
      <li>Vorname</li>
      <li>Nachname</li>
      <li>Ansprechpartner</li>
      <li>IK-Nummer</li>
      <li>Umsatzsteuer-ID</li>
      <li>Tätigkeitsbereich</li>
      <li>Straße / Hausnummer</li>
      <li>PLZ</li>
      <li>Ort</li>
      <li>Telefonnummer</li>
      <li>Faxnummer</li>
      <li>Mobilnummer</li>
      <li>E-Mail-Adresse</li>
      <li>Kontodaten</li>
      <li>freier Nachrichtentext</li>
    </ul>

    <h4 class="mt-3">Kategorien von Empfängern</h4>

    <p>
      Eine Übermittlung Ihrer Daten findet ausschließlich an Unternehmen innerhalb der opta data Gruppe (Auflistung
      unter
      <a href="https://www.optadata-gruppe.de/unternehmen/unternehmen-der-gruppe" target="_blank"
        >www.optadata-gruppe.de/unternehmen/unternehmen-der-gruppe</a
      >) statt.
    </p>
  </div>
  <!-- End Content -->
</div>
