<div class="hinweise-container box-shadow">
  <p class="title">Rechtliche Hinweise</p>

  <ul>
    <li>
      Ich versichere, im wirtschaftlichen Interesse des Kontoinhabers und nicht auf fremde Veranlassung (insbesondere
      nicht als Treuhänder) zu handeln.
    </li>
    <li>
      Ich bin uneingeschränkt befugt, die erforderlichen rechtlichen Erklärungen für und im Namen des Kontoinhabers
      abzugeben.
    </li>
    <li>Das Konto ist dem Betriebsvermögen des Kontoinhabers zuzurechnen (Angabe gemäß § 43 Abs. 5 ESt).</li>
  </ul>

  <div class="checkbox-card">
    <app-checkbox></app-checkbox>
    <p>Ich habe die rechtlichen Hinweise gelesen und bestätige diese</p>
  </div>

  <p class="selbstauskunft-title">Selbstauskunft zur Steuerpflicht</p>

  <div class="checkbox-card">
    <app-checkbox></app-checkbox>
    <p>
      Hiermit bestätige ich, dass der Kontoinhaber eine natürliche Person und nur in Deutschland steuerlich ansässig
      ist.
    </p>
  </div>
  <div class="checkbox-card">
    <app-checkbox></app-checkbox>
    <p>Hiermit bestätige ich, dass der Kontoinhaber kein Staatsangehöriger der Vereinigten Staaten von Amerika ist.</p>
  </div>
</div>
