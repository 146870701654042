import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ButtonComponent } from '../button/button.component';

@Component({
    selector: 'app-pdf-view-box',
    templateUrl: './pdf-view-box.component.html',
    styleUrls: ['./pdf-view-box.component.scss'],
    imports: [MatIcon, PdfViewerModule, ButtonComponent]
})
export class PdfViewBoxComponent {
  pdfSrc: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<any>
  ) {
    this.pdfSrc = data.pdfSrc;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
