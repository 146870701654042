<div class="row justify-content-center einsicht-container">
  <div class="col" style="display: flex; justify-content: center" *ngIf="step !== 4">
    <!--Step 1 Finapi Webform -->
    <div class="col" *ngIf="step === 1">
      <p class="text-center" appTranslationModal [translate]="'FINAPI.TITLE'"></p>
      <hr />
      <br />
      <p class="text-center" style="color: red" appTranslationModal [translate]="'FINAPI.hinweise'"></p>
      <p class="text-center mb-2" appTranslationModal [translate]="'FINAPI.TITLE_ZWEITE_REIHE'"></p>
      <div id="FinApiWeb">
        <div class="inprogress-container text-center" *ngIf="identityCheckStatus === 'inprogress'">
          <app-loader></app-loader>
        </div>
      </div>
    </div>
    <!--Step 2 Accountliste -->
    <div class="col-12 col-xl-10" *ngIf="step === 2">
      <p class="text-center" appTranslationModal [translate]="'FINAPI.ACCOUNTSPAGE_TITLE'"></p>
      <p class="text-center mb-2" appTranslationModal [translate]="'FINAPI.ACCOUNTSPAGE_TITLE_ZWEITE_REIHE'"></p>
      <app-bankacounts (next)="finalize()"></app-bankacounts>
    </div>
    <!--Step 3 KreditcheckAbschluss -->
    <div class="col-12 col-xl-10" *ngIf="step === 3">
      <app-kreditcheckabschluss
        (next)="showDocuments()"
        (documents)="getResDocuments($event)"
      ></app-kreditcheckabschluss>
    </div>
  </div>
</div>

<div *ngIf="step === 4">
  <div class="einsicht-layout">
    <div class="layout-top"><h1 appTranslationModal [translate]="'DOKUMENTESEITE.TITLE'"></h1></div>
    <div class="layout-main-left">
      <app-content-card>
        <form [formGroup]="answerFormGroup">
          <!-- <div class="filter-loading">
        <mat-spinner *ngIf="loading"></mat-spinner>
      </div> -->
          <h2 class="text-primary" appTranslationModal [translate]="'DOKUMENTESEITE.VERTRAEGE_TITLE'"></h2>
          <mat-accordion>
            <mat-expansion-panel
              class="expansion-panel-padding"
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header style="height: auto">
                <mat-panel-title class="text-bold" style="white-space: pre-line">
                  {{
                    panelOpenState ? 'Klicken Sie auf das Lupe-Symbol um das Dokument anzuschauen' : vertraegeTitel()
                  }}
                </mat-panel-title>
              </mat-expansion-panel-header>

              <table mat-table [dataSource]="verDataSource" class="full-width">
                <ng-container matColumnDef="name">
                  <td mat-cell *matCellDef="let element">
                    <h3 style="word-break: break-word">{{ element.name }}</h3>
                  </td>
                </ng-container>
                <ng-container matColumnDef="download">
                  <td mat-cell class="text-right" *matCellDef="let element">
                    <button
                      type="button"
                      class="search-button"
                      (click)="openPdf(element)"
                      data-cy="jurPerson_search-icon"
                    >
                      <mat-icon>search</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              </table>
            </mat-expansion-panel>
          </mat-accordion>
          <app-separator></app-separator>
          <h2 class="text-primary" appTranslationModal [translate]="'DOKUMENTESEITE.ALLGEMEINE_TITLE'"></h2>
          <mat-accordion>
            <mat-expansion-panel
              class="expansion-panel-padding"
              (opened)="panelOpenState2 = true"
              (closed)="panelOpenState2 = false"
            >
              <mat-expansion-panel-header style="height: auto">
                <mat-panel-title class="text-bold" style="white-space: pre-line">
                  {{
                    panelOpenState2
                      ? 'Klicken Sie auf das Lupe-Symbol um das Dokument anzuschauen'
                      : 'Datenschutzhinweise&#10;Informationsbogen für den Einleger&#10;SCHUFA Klausel \n &#10;Allgemeine Geschäftsbedingungen & Sonderbedingungen'
                  }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <table mat-table [dataSource]="dokDataSource" class="full-width">
                <ng-container matColumnDef="name">
                  <td mat-cell *matCellDef="let element">
                    <h3 style="word-break: break-word">{{ element.name }}</h3>
                  </td>
                </ng-container>
                <ng-container matColumnDef="download">
                  <td mat-cell class="text-right" *matCellDef="let element">
                    <button
                      type="button"
                      class="search-button"
                      (click)="openPdf(element)"
                      data-cy="jurPerson_search-icon"
                    >
                      <mat-icon>search</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              </table>

              <mat-accordion>
                <mat-expansion-panel
                  class="expansion-panel-padding"
                  (opened)="panelOpenState3 = true"
                  (closed)="panelOpenState3 = false"
                >
                  <mat-expansion-panel-header style="height: auto">
                    <mat-panel-title class="text-bold" style="white-space: pre-line">
                      {{
                        panelOpenState3
                          ? 'Klicken Sie auf das Lupe-Symbol um das Dokument anzuschauen'
                          : 'Allgemeine Geschäftsbedingungen & Sonderbedingungen'
                      }}
                    </mat-panel-title>
                    <!-- für das Online-Banking&#10;Sonderbedingungen für den Lastschriftverkehr&#10;Sonderbedingungen für den Überweisungsverkehr&#10;Sonderbedingungen für die Ausführung von Echtzeit-Überweisungen&#10;Sonderbedingungen für die girocard (Debitkarte)&#10;Sonderbedingungen für die Nutzung des elektronischen Postfachs-->
                  </mat-expansion-panel-header>
                  <table mat-table [dataSource]="agbDataSource" class="full-width">
                    <ng-container matColumnDef="name">
                      <td mat-cell *matCellDef="let element">
                        <h3 style="word-break: break-word">
                          {{ element.name }}
                        </h3>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="download">
                      <td mat-cell class="text-right" *matCellDef="let element">
                        <button
                          type="button"
                          class="search-button"
                          (click)="openPdf(element.position)"
                          data-cy="jurPerson_search-icon"
                        >
                          <mat-icon>search</mat-icon>
                        </button>
                      </td>
                    </ng-container>

                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                  </table>
                </mat-expansion-panel>
              </mat-accordion>
            </mat-expansion-panel>
          </mat-accordion>
        </form>
      </app-content-card>

      <div class="p-2">
        <span appTranslationModal [translate]="'DOKUMENTESEITE.PREIS_TEXT'"> </span>
        <span>&nbsp;</span>
        <span>
          <a
            target="_blank"
            appTranslationModal
            [translate]="'DOKUMENTESEITE.PREIS_LINK_TEXT'"
            href="https://www.bankhausbauer.de/fileadmin/user_upload/bankhausbauer/kundeninformationen/angepasst/preisverzeichnis.pdf"
          ></a>
        </span>
      </div>
      <div class="download-button-container">
        <app-button color="primary" (click)="downloadZip()" class="me-2 mb-2">
          <mat-icon>download</mat-icon> <span class="button-size-big">Alle Herunterladen</span>
        </app-button>
        <app-button color="primary" (click)="saveDoks()" *ngIf="isAdmin">
          <mat-icon>download</mat-icon>!!1.Admin save Docs!!
        </app-button>
        <app-button color="primary" (click)="archiveDoks()" *ngIf="isAdmin">
          <mat-icon>download</mat-icon>!!2.Admin archive Docs!!
        </app-button>
      </div>
    </div>
    <div class="layout-main-right">
      <app-content-card>
        <form style="margin-bottom: 1rem" [formGroup]="answerFormGroup">
          <h2 appTranslationModal [translate]="'DOKUMENTESEITE.VERTRAEGE_UEBERGABE_TITLE'"></h2>
          <hr />
          <p appTranslationModal [translate]="'DOKUMENTESEITE.VERTRAEGE_UEBERGABE_TEXT'"></p>

          <app-checkbox
            [checkBoxControl]="answerFormGroup.controls['bestätigt']"
            data-cy="kenntnisnahme_vertraege_dokumente"
          >
            <p appTranslationModal [translate]="'DOKUMENTESEITE.VERTRAEGE_UEBERGABE_CHECKBOX'"></p
          ></app-checkbox>
        </form>
      </app-content-card>
      <div class="mt-3">
        <app-button
          (click)="btnService.handleSaveAndDisableButton()"
          color="primary"
          [disabled]="btnService.isButtonDisabled() || btnService.isSaveAndContinueDisabled"
        >
          <span class="button-size-big" appTranslationModal [translate]="questionService.getNextButtonTitle()"></span>
        </app-button>
      </div>
    </div>
  </div>
</div>
