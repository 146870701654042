import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { WaveService } from './wave.service';

@Component({
  selector: 'app-wave-background',
  imports: [],
  templateUrl: './wave-background.component.html',
  styleUrl: './wave-background.component.scss',
})
export class WaveBackgroundComponent {
  waveService = inject(WaveService);
  constructor() {}
}
