<div class="pdf-container">
  <app-button class="pdf-button-wrapper" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </app-button>
</div>

<pdf-viewer
  [src]="pdfSrc"
  [original-size]="false"
  [rotation]="0"
  [show-all]="true"
  [fit-to-page]="false"
  [zoom]="1"
  [zoom-scale]="'page-width'"
  [stick-to-page]="false"
  [render-text]="true"
  [external-link-target]="'blank'"
  [autoresize]="true"
  [show-borders]="false"
></pdf-viewer>
