import { NgIf } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { ButtonComponent } from 'src/app/component/button/button.component';
import { Rol } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-header',
    imports: [ButtonComponent, NgIf],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss'
})
export class HeaderComponent {
  auth = inject(AuthService);
  keycloakService = inject(KeycloakService);
  router = inject(Router);
  Roles = Rol;

  @Input() showHeader: boolean;

  logout() {
    this.keycloakService.logout();
  }

  goToAdmin() {
    this.router.navigateByUrl('/admin');
  }
}
