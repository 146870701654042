import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import '@angular/common/locales/global/de';
import {
  ErrorHandler,
  LOCALE_ID,
  enableProdMode,
  importProvidersFrom,
  inject,
  isDevMode,
  provideAppInitializer,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LuxonDateAdapter, MAT_LUXON_DATE_ADAPTER_OPTIONS } from '@angular/material-luxon-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxFileDropModule } from 'ngx-file-drop';
import { QuillModule } from 'ngx-quill';
import { AppRoutingModule } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import { addBearerTokenInterceptor } from './app/interceptor/add-bearer-token.interceptor';
import { ServerErrorInterceptor } from './app/services/server-error.interceptor';
import { environment } from './environments/environment';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, `${environment.apiURL}/lang/`, '');
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD.MM.YYYY',
    monthYearA11yLabel: 'MMM YYYY',
  },
};

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: environment.odfinKeycloakConfig,
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
      },
      enableBearerInterceptor: true,
      bearerPrefix: 'Bearer',
      bearerExcludedUrls: [
        // environment.apiURL + "",
        'https://bankhausbauer.kontowechselservice.de/INTE/SSO.aspx',
      ],
    });
}

if (environment.production) {
  enableProdMode();
}

if (environment.name === 'dev') {
  Sentry.init({
    dsn: 'http://4c69e22896c7fe7212a68dcd49a4bb85@10.10.13.13:9000/2',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    environment: environment.name,
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^\//],
    // // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BackButtonDisableModule.forRoot(),
      KeycloakAngularModule,
      PdfViewerModule,
      BrowserModule,
      AppRoutingModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
      }),
      Ng2GoogleChartsModule,
      QuillModule.forRoot(),
      FormsModule,
      ReactiveFormsModule,
      NgxFileDropModule,
      MatFormFieldModule,
      MatInputModule,
      MatSelectModule,
      MatCheckboxModule,
      MatToolbarModule,
      MatProgressBarModule,
      MatCardModule,
      MatListModule,
      MatDividerModule,
      MatIconModule,
      MatSnackBarModule,
      MatNativeDateModule,
      MatDatepickerModule,
      MatDialogModule,
      MatExpansionModule,
      MatTabsModule,
      MatTableModule,
      MatRadioModule,
      MatMenuModule,
      MatAutocompleteModule,
      MatProgressSpinnerModule,
      MatPaginatorModule,
      MatSortModule,
      MatSidenavModule,
      MatStepperModule,
      MatExpansionModule,
      MatTooltipModule
    ),
    ...(environment.name === 'dev' || isDevMode()
      ? [
          {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
              showDialog: false,
            }),
          },
          {
            provide: Sentry.TraceService,
            deps: [Router],
          },
          provideAppInitializer(() => {
            const initializerFn = (() => () => {})();
            return initializerFn();
          }),
        ]
      : []),
    provideAppInitializer(() => {
      const initializerFn = initializeKeycloak(inject(KeycloakService));
      return initializerFn();
    }),
    DatePipe,
    { provide: LOCALE_ID, useValue: 'de' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'de' },
    { provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MatDialogRef, useValue: undefined },
    provideHttpClient(withInterceptors([addBearerTokenInterceptor])),
    provideAnimations(),
  ],
}).catch((err) => console.error(err));
