import { Component, OnInit } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss'],
  imports: [MatIcon],
})
export class DisclaimerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
