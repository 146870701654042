import { NgIf } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ButtonComponent } from 'src/app/component/button/button.component';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { AngebotAbhlenenDialogBoxComponent } from 'src/app/component/dialog-boxes/angebot-abhlenen-dialog-box/angebot-abhlenen-dialog-box.component';
import { Status, User } from 'src/app/models/user';
import { AdminService } from 'src/app/services/admin.service';
import { Agree21Service } from 'src/app/services/agree21.service';
import { AuthService } from 'src/app/services/auth.service';
import { productMatrixService } from 'src/app/services/product-matrix.service';
import { DataService } from 'src/app/services/data.service';
import { FinapiService } from 'src/app/services/finapi.service';
import { environment } from 'src/environments/environment';
import { LoaderComponent } from '../../../../../component/loader/loader.component';
import { TranslationDirective } from '../../../../../component/translata-modal/translation.directive';

@Component({
    selector: 'app-kreditcheckabschluss',
    templateUrl: './kreditcheckabschluss.component.html',
    styleUrls: ['./kreditcheckabschluss.component.scss'],
    imports: [NgIf, TranslationDirective, TranslateModule, ButtonComponent, MatIcon, LoaderComponent]
})
export class KreditcheckabschlussComponent implements OnInit {
  @Output() next = new EventEmitter();
  @Output() documents = new EventEmitter();
  creditLimitAmount: string;
  creditCardLimitAmount: string;
  creditLimitAmountNumber: number;
  creditCheckStatus;
  natPersonKontokorrenStatus;
  creditCardOrdered: boolean;
  kontoKorrentOrdered: boolean;
  skipFinapiProzess: boolean;
  creditCardSuccess: boolean;
  kontoKorrentSuccess: boolean;
  angebotAccepted: boolean;
  refreshUser: User;
  getOffer: boolean;
  finapiStop: boolean;
  waiting: boolean;
  deciedOffering: boolean;
  berufsGruppe: any;

  constructor(
    private finapiService: FinapiService,
    public auth: AuthService,
    public dialog: MatDialog,
    private router: Router,
    private agree21Service: Agree21Service,
    private dataService: DataService,
    private adminService: AdminService,
    public productMatrixService: productMatrixService
  ) {
    const userAnswer = this.auth.user.questions[0]?.answer;

    this.creditCardOrdered = userAnswer?.kreditKarte;
    this.kontoKorrentOrdered = userAnswer?.kontokorrentkredit;
    this.skipFinapiProzess =
      !userAnswer?.kontoWechselService && !userAnswer?.kontokorrentkredit && !userAnswer?.kreditKarte;
  }

  ngOnInit(): void {
    this.updateCreditCheckStatus();
    this.berufsGruppe = this.auth.getBerufsGruppe();
  }

  async updateCreditCheckStatus() {
    this.creditCheckStatus = this.skipFinapiProzess ? 'success' : 'inprogress';
    let result, decisionAccount;

    if (this.creditCheckStatus === 'inprogress') {
      this.angebotAccepted = false;
      try {
        if (window.sessionStorage.getItem('decisionAccount') === null) {
          await this.finapiService.buildCreditCheckobj();
          const result = await firstValueFrom(
            this.finapiService.creditCheck(
              this.finapiService.PROCESS_TOKEN,
              this.finapiService.ACCESS_TOKEN,
              this.auth.user.questions[0]?.answer.kreditKarte,
              this.finapiService.creditCheckType
            )
          );
          const { derivedCreditLimitAmount, derivedCreditCardLimitAmount, decisionCredit, decisionAccount } = result;
          this.documents.emit(result);
          window.sessionStorage.setItem('derivedCreditLimitAmount', derivedCreditLimitAmount);
          window.sessionStorage.setItem('derivedCreditCardLimitAmount', derivedCreditCardLimitAmount);
          window.sessionStorage.setItem('decisionCredit', decisionCredit);
          window.sessionStorage.setItem('decisionAccount', decisionAccount);

          this.handleFullCheck(decisionCredit, decisionAccount, derivedCreditLimitAmount, derivedCreditCardLimitAmount);
        } else {
          this.handleFullCheck(
            window.sessionStorage.getItem('decisionCredit'),
            window.sessionStorage.getItem('decisionAccount'),
            Number(window.sessionStorage.getItem('derivedCreditLimitAmount')),
            Number(window.sessionStorage.getItem('derivedCreditCardLimitAmount'))
          );
        }
      } catch (error) {
        this.handleCreditCheckError();
      }
    } else {
      if (window.sessionStorage.getItem('decisionAccount') === null) {
        await this.finapiService.buildCreditCheckobjSimple();
        try {
          const creditWebFormResponse = await firstValueFrom(
            this.finapiService.creditCheckWithoutWebForm(
              this.auth.user.questions[0]?.answer.kreditKarte,
              this.finapiService.creditCheckType
            )
          );
          this.documents.emit(result);
          decisionAccount = creditWebFormResponse.decisionAccount;
          this.getOffer = true;
          this.handleSimpleCheck(decisionAccount);
          window.sessionStorage.setItem('decisionAccount', decisionAccount);
        } catch (error) {
          //TODO handle error um waschmaschine zu entfernen
          this.getOffer = false;
        }
      } else {
        decisionAccount = window.sessionStorage.getItem('decisionAccount');
        this.getOffer = true;
        await this.handleSimpleCheck(decisionAccount);
      }
    }
  }

  async finalizeFinapiProcess() {
    let accessToken =
      this.finapiService.ACCESS_TOKEN !== undefined
        ? this.finapiService.ACCESS_TOKEN
        : window.sessionStorage.getItem('accessToken');
    let proccessToken =
      this.finapiService.PROCESS_TOKEN !== undefined
        ? this.finapiService.PROCESS_TOKEN
        : this.auth.user.finapiProcessToken;

    await this.finapiService.finalizeProcess(proccessToken, accessToken);
  }

  handleCreditCheckError() {
    // this.adminService.updateSubStatus(this.auth.user._id, "finapiStop");
    this.router.navigateByUrl('/info/error-page');
  }

  handleFullCheck(
    decisionCredit: string | null,
    decisionAccount: string | null,
    derivedCreditLimitAmount: number,
    derivedCreditCardLimitAmount: number
  ) {
    this.creditLimitAmountNumber = derivedCreditLimitAmount;
    if (decisionCredit === 'GREEN') {
      if (derivedCreditLimitAmount >= 0) {
        this.creditCheckStatus = 'success';
        this.getOffer = true;

        if (derivedCreditLimitAmount !== 0) {
          this.creditLimitAmount = this.formatCurrency(derivedCreditLimitAmount);
          this.kontoKorrentSuccess = true;
        } else {
          this.kontoKorrentSuccess = false;
        }

        if (this.creditCardOrdered && derivedCreditCardLimitAmount !== 0) {
          this.creditCardLimitAmount = this.formatCurrency(derivedCreditCardLimitAmount);
          this.creditCardSuccess = true;
        } else {
          this.creditCardSuccess = false;
        }

        this.finalizeFinapiProcess();
        this.adminService.updateSubStatus(this.auth.user._id, 'offering').subscribe(() => {});
      } else {
        //this.adminService.updateSubStatus(this.auth.user._id, "finapiStop");
        this.creditCheckStatus = 'failed';
      }
    }

    if (decisionCredit === 'RED') {
      if (decisionAccount === 'GREEN') {
        this.giveOffer();
      }
      if (decisionAccount === 'RED') {
        this.noOffer();
      }
    }
  }

  async handleSimpleCheck(decisionAccount: string) {
    if (decisionAccount === 'GREEN') {
      this.giveOffer();
    }
    if (decisionAccount === 'RED') {
      this.noOffer();
    }
  }

  giveOffer() {
    this.deciedOffering = true;
    this.getOffer = true;
    this.kontoKorrentSuccess = true;
    this.creditCardSuccess = true;
    this.adminService.updateSubStatus(this.auth.user._id, 'offering').subscribe(() => {});
  }

  noOffer() {
    this.finapiStop = true;
    this.getOffer = false;
    this.kontoKorrentSuccess = false;
    this.creditCardSuccess = false;
    this.creditCheckStatus = 'failed';
    this.adminService.updateSubStatus(this.auth.user._id, 'Account_rejected').subscribe(() => {});
  }

  async nextstep() {
    this.angebotAccepted = true;
    this.waiting = true;
    const isGbR: boolean = this.auth.user?.JurPerson?.JurDaten?.GbrArt === 'GbR ohne Haftungsbeschraenkung';
    if (this.auth.isJurPerson() && !isGbR) {
      await this.callBuildAgreeJurPerson();
    } else if (isGbR) {
      await this.callBuildAgreeGbRPersonObj();
    } else {
      await this.callBuildAgreeNatPerson();
    }
  }

  async callBuildAgreeJurPerson() {
    this.natPersonKontokorrenStatus = 'inprogress';

    if (!this.auth.user?.JurPerson.JurDaten.BBKundennummer) {
      if (this.skipFinapiProzess) {
        this.creditLimitAmountNumber = 0;
      }
      this.agree21Service.postJurPerson(this.creditLimitAmountNumber).subscribe({
        next: (result) => {
          if (result.jurPersonnummer) {
            this.updateUserJurData(result);
            this.finalizeProcess();
          }
        },
        error: (error) => this.handleError(error),
      });
    } else {
      this.finalizeProcess();
    }
  }

  async callBuildAgreeGbRPersonObj() {
    this.natPersonKontokorrenStatus = 'inprogress';

    if (!this.auth.user?.JurPerson.JurDaten.BBKundennummer) {
      if (this.skipFinapiProzess) {
        this.creditLimitAmountNumber = 0;
      }
      this.agree21Service.postGbRPerson(this.creditLimitAmountNumber).subscribe({
        next: (result) => {
          if (result.gbrPersonnummer) {
            this.updateUserGbRData(result);
            this.finalizeProcess();
          }
        },
        error: (error) => this.handleError(error),
      });
    } else {
      this.finalizeProcess();
    }
  }

  async callBuildAgreeNatPerson() {
    this.natPersonKontokorrenStatus = 'inprogress';

    if (!this.auth.user?.kundenNummer) {
      if (this.skipFinapiProzess) {
        this.creditLimitAmountNumber = 0;
      }
      this.agree21Service.postNatPersonKontokorrent(this.creditLimitAmountNumber).subscribe((result) => {
        if (result.personennummer) {
          this.updateUserNatData(result);
          this.finalizeProcess();
        } else {
          this.router.navigateByUrl('/info/error-page');
        }
      });
    } else {
      this.finalizeProcess();
    }
  }

  updateUserJurData(result) {
    this.agree21Service.PERSONEN_NUMMER = result.jurPersonnummer;
    this.agree21Service.IBAN = result.iban;
    this.auth.user.JurPerson.JurDaten.BBKundennummer = result.jurPersonnummer;
    this.auth.user.iban = result.iban;
    this.auth.user.tagesGeldkontoIban = result.tagesGeldkontoIban;
  }

  updateUserGbRData(result) {
    this.agree21Service.PERSONEN_NUMMER = result.gbrPersonnummer;
    this.agree21Service.IBAN = result.iban;
    this.auth.user.JurPerson.JurDaten.BBKundennummer = result.gbrPersonnummer;
    this.auth.user.iban = result.iban;
    this.auth.user.tagesGeldkontoIban = result.tagesGeldkontoIban;
  }

  updateUserNatData(result) {
    this.agree21Service.PERSONEN_NUMMER = result.personennummer;
    this.agree21Service.IBAN = result.iban;
    this.auth.user.kundenNummer = result.personennummer;
    this.auth.user.iban = result.iban;
    this.auth.user.tagesGeldkontoIban = result.tagesGeldkontoIban;
  }

  finalizeProcess() {
    this.natPersonKontokorrenStatus = 'success';
    this.next.emit();
  }

  handleError(error) {
    throw new Error(`Failed - ${JSON.stringify(error)}`);
  }

  formatCurrency(amount) {
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
      maximumSignificantDigits: 7,
    }).format(amount);
  }

  openPopUp() {
    this.dialog
      .open(AngebotAbhlenenDialogBoxComponent)
      .afterClosed()
      .subscribe(async (answer) => {
        if (answer == true) {
          this.adminService.updateSubStatus(this.auth.user._id, 'offeringRejected').subscribe(() => {
            this.auth.changeStatus(this.auth.user._id, Status.inactive).subscribe(() => {
              this.openOne();
            });
          });
        }
      });
  }

  openOne() {
    window.location.href = environment.oneDashboardURL;
  }
}
