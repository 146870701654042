<div class="text">
  <div class="header-container mb-5">
    <h1 class="white ps-5">Impressum</h1>
  </div>
  <div class="ps-5">
    <p>Angaben gemäß § 5 TMG</p>
    <h2 class="mt-5">Herausgeber</h2>
    <p>opta data Finance GmbH</p>

    <h2 class="mt-5">Sitz:</h2>
    <p>
      Berthold-Beitz-Boulevard 461<br />
      45141 Essen
    </p>

    <h2 class="mt-5">Handelsregister:</h2>
    <p>Amtsgericht Essen HRB 1995</p>

    <h2 class="mt-5">Umsatzsteuer-ID:</h2>
    <p>
      Umsatzsteuer-Identifikationsnummer gemäß § 27a Umsatzsteuergesetz:<br />
      DE119652507
    </p>

    <h2 class="mt-5">Vertretungsberechtigte Geschäftsführer:</h2>
    <p>Mark Steinbach, Andreas Fischer</p>

    <h2 class="mt-5">Kontakt:</h2>
    <p>
      Telefon: +49 201 31960<br />
      Telefax: +49 201 3196222<br />
      E-Mail:
      <a href="mailto:service@optadata-gruppe.de">service&#64;optadata-gruppe.de</a>
    </p>

    <h2 class="mt-5">Postanschrift:</h2>
    <p>
      Postfach 12 03 13<br />
      45313 Essen<br />
    </p>

    <h2 class="mt-5">Aufsichtsbehörde:</h2>
    <p>
      Bundesanstalt für Finanzdienstleistungsaufsicht (BaFin)<br />
      Graurheindorfer Straße 108<br />
      53117 Bonn<br />
      www.bafin.de
    </p>

    <h2 class="mt-5">Registrierungsbehörde/Aufsichtsbehörde nach dem Rechtsdienstleistungsgesetz</h2>
    <span class="mb-2 d-inline-block">(§ 10 I S. 1 Nr. 1 RDG)</span>
    <p>
      Oberlandesgericht Hamm<br />
      Hesslerstraße 53,<br />
      59065 Hamm<br />
      www.olg-hamm.nrw.de<br />
      Aktenzeichen: 3712-8.115<br />
      www.rechtsdienstleistungsregister.de
    </p>

    <h2 class="mt-5">Information zur Verbraucherschlichtungsstelle gem. § 36 VSBG</h2>
    <p>Wir nehmen nicht an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teil.</p>

    <h2 class="mt-5">Inhaltlich verantwortlich</h2>
    <p>
      Herolind Vladi<br />
      opta data Stiftung &amp; Co. KG<br />
      Berthold-Beitz-Boulevard 514<br />
      45141 Essen
    </p>

    <h2 class="mt-5">Redaktionell verantwortlich</h2>
    <p>
      Herolind Vladi<br />
      opta data Stiftung &amp; Co. KG<br />
      Berthold-Beitz-Boulevard 514<br />
      45141 Essen
    </p>

    <h2 class="mt-5">Technische Umsetzung</h2>
    <p class="mb-5">
      opta data Finance GmbH<br />
      Berthold-Beitz-Boulevard 514<br />
      45141 Essen<br />
      https://www.optadata.de/
    </p>
  </div>
</div>
