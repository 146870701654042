import { AuthService } from 'src/app/services/auth.service';
import { productMatrixService } from 'src/app/services/product-matrix.service';
import { QuestionService } from 'src/app/services/question.service';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Status } from 'src/app/models/user';
import { KwsService } from 'src/app/services/kws.service';
import { environment } from 'src/environments/environment';
import { QuestionTemplate } from '../question-template';
import { AdminService } from 'src/app/services/admin.service';
import { TranslationDirective } from '../../../../component/translata-modal/translation.directive';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { ButtonComponent } from 'src/app/component/button/button.component';
import { ModalDialogComponent } from 'src/app/component/modal-dialog/modal-dialog.component';
import { SeparatorComponent } from 'src/app/component/separator/separator.component';
import { ContentCardComponent } from '../../../../component/content-card/content-card.component';

@Component({
  selector: 'app-identifizierung',
  templateUrl: './identifizierung.component.html',
  styleUrls: ['./identifizierung.component.scss'],
  imports: [
    TranslationDirective,
    TranslateModule,
    NgIf,
    ButtonComponent,
    ModalDialogComponent,
    SeparatorComponent,
    ContentCardComponent,
  ],
})
export class IdentifizierungComponent extends QuestionTemplate implements OnInit, AfterViewInit {
  public customerName: string;
  public customerIban: string;
  public kontoWechselSelected: boolean;
  tagesGeldkontoIban: string;
  berufsGruppe: any;
  enableTagesgeldkonto: boolean;
  isInfoBeforeLeavingVisible = false;

  constructor(
    private kwsservice: KwsService,
    auth: AuthService,
    questionService: QuestionService,
    route: ActivatedRoute,
    public productMatrixService: productMatrixService,
    private adminService: AdminService
  ) {
    super(auth, questionService, route);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.customerName =
      this.auth.user.natPersonen[this.auth.index].Person.Titel +
      ' ' +
      this.auth.user.name +
      ' ' +
      this.auth.user.lastname;
    this.customerIban = this.changeFormat(this.auth.user.iban);
    this.tagesGeldkontoIban = this.changeFormat(this.auth.user.tagesGeldkontoIban);
    this.productMatrixService
      .getBerufsGruppe(this.auth.user.natPersonen[this.auth.index].BranchenInfo.Art)
      .subscribe((berufsGruppe) => {
        this.berufsGruppe = berufsGruppe;
      });
    this.auth.getConfig().subscribe({
      next: (response) => {
        this.enableTagesgeldkonto = response?.data?.enableTagesgeldkonto ?? false;
      },
    });
    this.kontoWechselSelected = this.auth.user.questions[0]?.answer.kontoWechselService;
  }
  ngAfterViewInit() {
    this.auth.hasKontowechselserviceSelected().subscribe((res) => {
      this.kontoWechselSelected = res;
    });
    if (this.auth.user.status == Status.finish) {
      if (this.kontoWechselSelected) {
        this.openKontoWechsel();
      } else {
        this.redirectDashboardOne();
      }
    }
  }

  openDialogBeforeGoingToOne() {
    this.isInfoBeforeLeavingVisible = true;
  }

  redirectDashboardOne() {
    window.location.href = environment.oneDashboardURL;
  }

  kwsURL() {
    return environment.KwsAPI.url;
  }

  openKontoWechsel() {
    const kundendatenField: HTMLElement | null = document.getElementById('kundendaten');
    const signaturField = document.getElementById('signatur') as HTMLElement;

    this.kwsservice.buildKundenDaten().then(() => {
      this.kwsservice.generateSignatur().subscribe((response) => {
        if (kundendatenField) {
          kundendatenField.setAttribute('value', JSON.stringify(this.kwsservice.kundendaten));
        }
        if (signaturField) {
          signaturField.setAttribute('value', response.signatur);
        }

        const kwsButton = document.getElementById('formSubmit');

        if (kwsButton) {
          kwsButton.click();
        }

        this.adminService.updateSubStatus(this.auth.user._id, 'kws').subscribe(() => {});
      });
    });
  }

  changeFormat(iban: string) {
    let newIban = [
      iban?.substring(0, 4),
      iban?.substring(4, 8),
      iban?.substring(8, 12),
      iban?.substring(12, 16),
      iban?.substring(16, 20),
      iban?.substring(20, 22),
    ].join(' ');
    return newIban;
  }

  copyToClipboard(text) {
    navigator.clipboard.writeText(text);
  }

  meduplusButton() {
    window.location.href = environment.meduplus.url;
  }
}
