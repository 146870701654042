import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.scss'],
  imports: [],
})
export class ImpressumComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
